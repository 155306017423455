module.exports = {
  firebaseProjectId: 'birder-7c2ac',
  firebaseCredentialsFile: __dirname + '/firebase-credentials.json',
  firebaseApiKey: 'AIzaSyCjwBRWdMMZGCx_QryxOzjPGBAMoqd4bAk',
  firebaseStorageBucket: 'birder-7c2ac.appspot.com',
  firestoreURL: 'https://birder-7c2ac.firebaseio.com',
  firebaseMessageSenderId: '261441833609',
  authDomain: 'birdergame.com',
  sentryDsn: 'https://e2f0aebeccac4650a1ae09e1173183b6@sentry.io/1516969',
  firebaseAppId: '1:261441833609:web:076174b68417ac29fb1458'
}
