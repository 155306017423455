module.exports = [
  {
    id: 'b-1',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Kyhmyjoutsen',
    nameLatin: 'Cygnus olor',
    nameEn: 'Mute Swan',
    rarity: 1,
    photo: 'kyhmyjoutsen.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kyhmyjoutsen'
  },
  {
    id: 'b-2',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Pikkujoutsen',
    nameLatin: 'Cygnus columbianus',
    nameEn: 'Tundra Swan',
    rarity: 3,
    photo: 'pikkujoutsen.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pikkujoutsen'
  },
  {
    id: 'b-3',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Laulujoutsen',
    nameLatin: 'Cygnus cygnus',
    nameEn: 'Whooper Swan',
    rarity: 1,
    photo: 'laulujoutsen.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/laulujoutsen'
  },
  {
    id: 'b-4',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Metsähanhi',
    nameLatin: 'Anser fabalis',
    nameEn: 'Bean Goose',
    rarity: 2,
    photo: 'metsahanhi.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/metsahanhi'
  },
  {
    id: 'b-5',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Tundrahanhi',
    nameLatin: 'Anser albifrons',
    nameEn: 'Greater White-fronted Goose',
    rarity: 2,
    photo: 'tundrahanhi.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/tundrahanhi'
  },
  {
    id: 'b-6',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Merihanhi',
    nameLatin: 'Anser anser',
    nameEn: 'Greylag Goose',
    rarity: 2,
    photo: 'merihanhi.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/merihanhi'
  },
  {
    id: 'b-7',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Kanadanhanhi',
    nameLatin: 'Branta canadensis',
    nameEn: 'Canada Goose',
    rarity: 2,
    photo: 'kanadanhanhi.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kanadanhanhi'
  },
  {
    id: 'b-8',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Valkoposkihanhi',
    nameLatin: 'Branta leucopsis',
    nameEn: 'Barnacle Goose',
    rarity: 1,
    photo: 'valkoposkihanhi.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/valkoposkihanhi'
  },
  {
    id: 'b-9',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Haapana',
    nameLatin: 'Anas penelope',
    nameEn: 'Eurasian Wigeon',
    rarity: 2,
    photo: 'haapana.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/haapana'
  },
  {
    id: 'b-10',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Harmaasorsa',
    nameLatin: 'Anas strepera',
    nameEn: 'Gadwall',
    rarity: 3,
    photo: 'harmaasorsa.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/harmaasorsa'
  },
  {
    id: 'b-11',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Tavi',
    nameLatin: 'Anas crecca',
    nameEn: 'Eurasian Teal',
    rarity: 2,
    photo: 'tavi.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/tavi'
  },
  {
    id: 'b-12',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Sinisorsa',
    nameLatin: 'Anas platyrhynchos',
    nameEn: 'Mallard',
    rarity: 1,
    photo: 'sinisorsa.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/sinisorsa'
  },
  {
    id: 'b-13',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Jouhisorsa',
    nameLatin: 'Anas acuta',
    nameEn: 'Northern Pintail',
    rarity: 2,
    photo: 'jouhisorsa.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/jouhisorsa'
  },
  {
    id: 'b-14',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Lapasorsa',
    nameLatin: 'Anas clypeata',
    nameEn: 'Northern Shoveler',
    rarity: 3,
    photo: 'lapasorsa.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/lapasorsa'
  },
  {
    id: 'b-15',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Tukkasotka',
    nameLatin: 'Aythya fuligula',
    nameEn: 'Tufted Duck',
    rarity: 3,
    photo: 'tukkasotka.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/tukkasotka'
  },
  {
    id: 'b-16',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Haahka',
    nameLatin: 'Somateria mollissima',
    nameEn: 'Common Eider',
    rarity: 3,
    photo: 'haahka.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/haahka'
  },
  {
    id: 'b-17',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Alli',
    nameLatin: 'Clangula hyemalis',
    nameEn: 'Long-tailed Duck',
    rarity: 2,
    photo: 'alli.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/alli'
  },
  {
    id: 'b-18',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Mustalintu',
    nameLatin: 'Melanitta nigra',
    nameEn: 'Common Scoter',
    rarity: 3,
    photo: 'mustalintu.jpg',
    photographer: 'Liam Sanchez',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/mustalintu'
  },
  {
    id: 'b-19',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Pilkkasiipi',
    nameLatin: 'Melanitta fusca',
    nameEn: 'Velvet Scoter',
    rarity: 3,
    photo: 'pilkkasiipi.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pilkkasiipi'
  },
  {
    id: 'b-20',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Laniidae',
    familyFi: 'Lepinkäiset',
    nameFi: 'Punapäälepinkäinen',
    nameLatin: 'Lanius senator',
    nameEn: 'Woodchat Shrike',
    rarity: 5,
    photo: 'punapaalepinkainen.jpg',
    photographer: 'Liam Sanchez',
    detectLink: 'https://fi.wikipedia.org/wiki/Punapäälepinkäinen'
  },
  {
    id: 'b-21',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Telkkä',
    nameLatin: 'Bucephala clangula',
    nameEn: 'Common Goldeneye',
    rarity: 1,
    photo: 'telkka.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/telkka'
  },
  {
    id: 'b-22',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Uivelo',
    nameLatin: 'Mergellus albellus',
    nameEn: 'Smew',
    rarity: 3,
    photo: 'uivelo.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/uivelo'
  },
  {
    id: 'b-23',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Tukkakoskelo',
    nameLatin: 'Mergus serrator',
    nameEn: 'Red-breasted Merganser',
    rarity: 2,
    photo: 'tukkakoskelo.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/tukkakoskelo'
  },
  {
    id: 'b-24',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Isokoskelo',
    nameLatin: 'Mergus merganser',
    nameEn: 'Common Merganser',
    rarity: 1,
    photo: 'isokoskelo.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/isokoskelo'
  },
  {
    id: 'b-25',
    orderLatin: 'Galliformes',
    orderFi: 'Kanalinnut',
    familyLatin: 'Phasianidae',
    familyFi: 'Aitokanat',
    nameFi: 'Pyy',
    nameLatin: 'Tetrastes bonasia',
    nameEn: 'Hazel Grouse',
    rarity: 3,
    photo: 'pyy.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pyy'
  },
  {
    id: 'b-26',
    orderLatin: 'Galliformes',
    orderFi: 'Kanalinnut',
    familyLatin: 'Phasianidae',
    familyFi: 'Aitokanat',
    nameFi: 'Riekko',
    nameLatin: 'Lagopus lagopus',
    nameEn: 'Willow Ptarmigan',
    rarity: 4,
    photo: 'riekko.jpg',
    photographer: 'Henrikki Hetemaa',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/riekko'
  },
  {
    id: 'b-27',
    orderLatin: 'Galliformes',
    orderFi: 'Kanalinnut',
    familyLatin: 'Phasianidae',
    familyFi: 'Aitokanat',
    nameFi: 'Kiiruna',
    nameLatin: 'Lagopus muta',
    nameEn: 'Rock Ptarmigan',
    rarity: 5,
    photo: 'kiiruna.jpg',
    photographer: 'Henrikki Hetemaa',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kiiruna'
  },
  {
    id: 'b-28',
    orderLatin: 'Galliformes',
    orderFi: 'Kanalinnut',
    familyLatin: 'Phasianidae',
    familyFi: 'Aitokanat',
    nameFi: 'Teeri',
    nameLatin: 'Tetrao tetrix',
    nameEn: 'Black Grouse',
    rarity: 2,
    photo: 'teeri.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/teeri'
  },
  {
    id: 'b-29',
    orderLatin: 'Galliformes',
    orderFi: 'Kanalinnut',
    familyLatin: 'Phasianidae',
    familyFi: 'Aitokanat',
    nameFi: 'Metso',
    nameLatin: 'Tetrao urogallus',
    nameEn: 'Western Capercaillie',
    rarity: 3,
    photo: 'metso.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/metso'
  },
  {
    id: 'b-30',
    orderLatin: 'Galliformes',
    orderFi: 'Kanalinnut',
    familyLatin: 'Phasianidae',
    familyFi: 'Aitokanat',
    nameFi: 'Peltopyy',
    nameLatin: 'Perdix perdix',
    nameEn: 'Grey Partridge',
    rarity: 4,
    photo: 'peltopyy.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/peltopyy'
  },
  {
    id: 'b-31',
    orderLatin: 'Galliformes',
    orderFi: 'Kanalinnut',
    familyLatin: 'Phasianidae',
    familyFi: 'Aitokanat',
    nameFi: 'Fasaani',
    nameLatin: 'Phasianus colchicus',
    nameEn: 'Common Pheasant',
    rarity: 2,
    photo: 'fasaani.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/fasaani'
  },
  {
    id: 'b-32',
    orderLatin: 'Gaviiformes',
    orderFi: 'Kuikkalinnut',
    familyLatin: 'Gaviidae',
    familyFi: 'Kuikat',
    nameFi: 'Kaakkuri',
    nameLatin: 'Gavia stellata',
    nameEn: 'Red-throated Loon',
    rarity: 3,
    photo: 'kaakkuri.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kaakkuri'
  },
  {
    id: 'b-33',
    orderLatin: 'Gaviiformes',
    orderFi: 'Kuikkalinnut',
    familyLatin: 'Gaviidae',
    familyFi: 'Kuikat',
    nameFi: 'Kuikka',
    nameLatin: 'Gavia arctica',
    nameEn: 'Black-throated Loon',
    rarity: 2,
    photo: 'kuikka.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kuikka'
  },
  {
    id: 'b-34',
    orderLatin: 'Podicipediformes',
    orderFi: 'Uikkulinnut',
    familyLatin: 'Podicipedidae',
    familyFi: 'Uikut',
    nameFi: 'Silkkiuikku',
    nameLatin: 'Podiceps cristatus',
    nameEn: 'Great Crested Grebe',
    rarity: 2,
    photo: 'silkkiuikku.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/silkkiuikku'
  },
  {
    id: 'b-35',
    orderLatin: 'Podicipediformes',
    orderFi: 'Uikkulinnut',
    familyLatin: 'Podicipedidae',
    familyFi: 'Uikut',
    nameFi: 'Härkälintu',
    nameLatin: 'Podiceps grisegena',
    nameEn: 'Red-necked Grebe',
    rarity: 2,
    photo: 'harkalintu.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/harkalintu'
  },
  {
    id: 'b-36',
    orderLatin: 'Podicipediformes',
    orderFi: 'Uikkulinnut',
    familyLatin: 'Podicipedidae',
    familyFi: 'Uikut',
    nameFi: 'Mustakurkku-uikku',
    nameLatin: 'Podiceps auritus',
    nameEn: 'Horned Grebe',
    rarity: 3,
    photo: 'mustakurkku-uikku.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/mustakurkku-uikku'
  },
  {
    id: 'b-37',
    orderLatin: 'Pelecaniformes',
    orderFi: 'Pelikaanilinnut',
    familyLatin: 'Phalacrocoracidae',
    familyFi: 'Merimetsot',
    nameFi: 'Merimetso',
    nameLatin: 'Phalacrocorax carbo',
    nameEn: 'Great Cormorant',
    rarity: 2,
    photo: 'merimetso.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/merimetso'
  },
  {
    id: 'b-38',
    orderLatin: 'Ciconiiformes',
    orderFi: 'Haikaralinnut',
    familyLatin: 'Ardeidae',
    familyFi: 'Haikarat',
    nameFi: 'Kaulushaikara',
    nameLatin: 'Botaurus stellaris',
    nameEn: 'Eurasian Bittern',
    rarity: 3,
    photo: 'kaulushaikara.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kaulushaikara'
  },
  {
    id: 'b-39',
    orderLatin: 'Ciconiiformes',
    orderFi: 'Haikaralinnut',
    familyLatin: 'Ardeidae',
    familyFi: 'Haikarat',
    nameFi: 'Jalohaikara',
    nameLatin: 'Egretta alba',
    nameEn: 'Great Egret',
    rarity: 4,
    photo: 'jalohaikara.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/jalohaikara'
  },
  {
    id: 'b-40',
    orderLatin: 'Ciconiiformes',
    orderFi: 'Haikaralinnut',
    familyLatin: 'Ardeidae',
    familyFi: 'Haikarat',
    nameFi: 'Harmaahaikara',
    nameLatin: 'Ardea cinerea',
    nameEn: 'Grey Heron',
    rarity: 2,
    photo: 'harmaahaikara.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/harmaahaikara'
  },
  {
    id: 'b-41',
    orderLatin: 'Accipitriformes',
    orderFi: 'Päiväpetolinnut',
    familyLatin: 'Accipitridae',
    familyFi: 'Haukat',
    nameFi: 'Mehiläishaukka',
    nameLatin: 'Pernis apivorus',
    nameEn: 'European Honey Buzzard',
    rarity: 3,
    photo: 'mehilaishaukka.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/mehilaishaukka'
  },
  {
    id: 'b-42',
    orderLatin: 'Accipitriformes',
    orderFi: 'Päiväpetolinnut',
    familyLatin: 'Accipitridae',
    familyFi: 'Haukat',
    nameFi: 'Haarahaukka',
    nameLatin: 'Milvus migrans',
    nameEn: 'Black Kite',
    rarity: 4,
    photo: 'haarahaukka.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/haarahaukka'
  },
  {
    id: 'b-43',
    orderLatin: 'Accipitriformes',
    orderFi: 'Päiväpetolinnut',
    familyLatin: 'Accipitridae',
    familyFi: 'Haukat',
    nameFi: 'Merikotka',
    nameLatin: 'Haliaeetus albicilla',
    nameEn: 'White-tailed Eagle',
    rarity: 2,
    photo: 'merikotka.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/merikotka'
  },
  {
    id: 'b-44',
    orderLatin: 'Accipitriformes',
    orderFi: 'Päiväpetolinnut',
    familyLatin: 'Accipitridae',
    familyFi: 'Haukat',
    nameFi: 'Ruskosuohaukka',
    nameLatin: 'Circus aeruginosus',
    nameEn: 'Western Marsh Harrier',
    rarity: 2,
    photo: 'ruskosuohaukka.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/ruskosuohaukka'
  },
  {
    id: 'b-45',
    orderLatin: 'Accipitriformes',
    orderFi: 'Päiväpetolinnut',
    familyLatin: 'Accipitridae',
    familyFi: 'Haukat',
    nameFi: 'Arosuohaukka',
    nameLatin: 'Circus macrourus',
    nameEn: 'Pallid Harrier',
    rarity: 4,
    photo: 'arosuohaukka.jpg',
    photographer: 'Timo Saari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/arosuohaukka'
  },
  {
    id: 'b-46',
    orderLatin: 'Accipitriformes',
    orderFi: 'Päiväpetolinnut',
    familyLatin: 'Accipitridae',
    familyFi: 'Haukat',
    nameFi: 'Niittysuohaukka',
    nameLatin: 'Circus pygargus',
    nameEn: "Montagu's Harrier",
    rarity: 4,
    photo: 'niittysuohaukka.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/niittysuohaukka'
  },
  {
    id: 'b-47',
    orderLatin: 'Accipitriformes',
    orderFi: 'Päiväpetolinnut',
    familyLatin: 'Accipitridae',
    familyFi: 'Haukat',
    nameFi: 'Sinisuohaukka',
    nameLatin: 'Circus cyaneus',
    nameEn: 'Northern Harrier',
    rarity: 3,
    photo: 'sinisuohaukka.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/sinisuohaukka'
  },
  {
    id: 'b-48',
    orderLatin: 'Accipitriformes',
    orderFi: 'Päiväpetolinnut',
    familyLatin: 'Accipitridae',
    familyFi: 'Haukat',
    nameFi: 'Kanahaukka',
    nameLatin: 'Accipiter gentilis',
    nameEn: 'Northern Goshawk',
    rarity: 2,
    photo: 'kanahaukka.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kanahaukka'
  },
  {
    id: 'b-49',
    orderLatin: 'Accipitriformes',
    orderFi: 'Päiväpetolinnut',
    familyLatin: 'Accipitridae',
    familyFi: 'Haukat',
    nameFi: 'Varpushaukka',
    nameLatin: 'Accipiter nisus',
    nameEn: 'Eurasian Sparrowhawk',
    rarity: 2,
    photo: 'varpushaukka.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/varpushaukka'
  },
  {
    id: 'b-50',
    orderLatin: 'Accipitriformes',
    orderFi: 'Päiväpetolinnut',
    familyLatin: 'Accipitridae',
    familyFi: 'Haukat',
    nameFi: 'Hiirihaukka',
    nameLatin: 'Buteo buteo',
    nameEn: 'Common Buzzard',
    rarity: 2,
    photo: 'hiirihaukka.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/hiirihaukka'
  },
  {
    id: 'b-51',
    orderLatin: 'Accipitriformes',
    orderFi: 'Päiväpetolinnut',
    familyLatin: 'Accipitridae',
    familyFi: 'Haukat',
    nameFi: 'Piekana',
    nameLatin: 'Buteo lagopus',
    nameEn: 'Rough-legged Buzzard',
    rarity: 3,
    photo: 'piekana.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/piekana'
  },
  {
    id: 'b-52',
    orderLatin: 'Accipitriformes',
    orderFi: 'Päiväpetolinnut',
    familyLatin: 'Accipitridae',
    familyFi: 'Haukat',
    nameFi: 'Maakotka',
    nameLatin: 'Aquila chrysaetos',
    nameEn: 'Golden Eagle',
    rarity: 3,
    photo: 'maakotka.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/maakotka'
  },
  {
    id: 'b-53',
    orderLatin: 'Accipitriformes',
    orderFi: 'Päiväpetolinnut',
    familyLatin: 'Pandionidae',
    familyFi: 'Sääkset',
    nameFi: 'Kalasääski',
    nameLatin: 'Pandion haliaetus',
    nameEn: 'Osprey',
    rarity: 2,
    photo: 'kalasaaski.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/saaksi'
  },
  {
    id: 'b-54',
    orderLatin: 'Falconiformes',
    orderFi: 'Jalohaukkalinnut',
    familyLatin: 'Falconidae',
    familyFi: 'Jalohaukat',
    nameFi: 'Tuulihaukka',
    nameLatin: 'Falco tinnunculus',
    nameEn: 'Common Kestrel',
    rarity: 2,
    photo: 'tuulihaukka.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/tuulihaukka'
  },
  {
    id: 'b-55',
    orderLatin: 'Falconiformes',
    orderFi: 'Jalohaukkalinnut',
    familyLatin: 'Falconidae',
    familyFi: 'Jalohaukat',
    nameFi: 'Punajalkahaukka',
    nameLatin: 'Falco vespertinus',
    nameEn: 'Red-footed Falcon',
    rarity: 4,
    photo: 'punajalkahaukka.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/punajalkahaukka'
  },
  {
    id: 'b-56',
    orderLatin: 'Falconiformes',
    orderFi: 'Jalohaukkalinnut',
    familyLatin: 'Falconidae',
    familyFi: 'Jalohaukat',
    nameFi: 'Ampuhaukka',
    nameLatin: 'Falco columbarius',
    nameEn: 'Merlin',
    rarity: 3,
    photo: 'ampuhaukka.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/ampuhaukka'
  },
  {
    id: 'b-57',
    orderLatin: 'Falconiformes',
    orderFi: 'Jalohaukkalinnut',
    familyLatin: 'Falconidae',
    familyFi: 'Jalohaukat',
    nameFi: 'Muuttohaukka',
    nameLatin: 'Falco peregrinus',
    nameEn: 'Peregrine Falcon',
    rarity: 3,
    photo: 'muuttohaukka.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/muuttohaukka'
  },
  {
    id: 'b-58',
    orderLatin: 'Falconiformes',
    orderFi: 'Jalohaukkalinnut',
    familyLatin: 'Falconidae',
    familyFi: 'Jalohaukat',
    nameFi: 'Nuolihaukka',
    nameLatin: 'Falco subbuteo',
    nameEn: 'Eurasian Hobby',
    rarity: 3,
    photo: 'nuolihaukka.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/nuolihaukka'
  },
  {
    id: 'b-59',
    orderLatin: 'Gruiformes',
    orderFi: 'Kurkilinnut',
    familyLatin: 'Rallidae',
    familyFi: 'Rantakanat',
    nameFi: 'Luhtakana',
    nameLatin: 'Rallus aquaticus',
    nameEn: 'Water Rail',
    rarity: 2,
    photo: 'luhtakana.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/luhtakana'
  },
  {
    id: 'b-60',
    orderLatin: 'Gruiformes',
    orderFi: 'Kurkilinnut',
    familyLatin: 'Rallidae',
    familyFi: 'Rantakanat',
    nameFi: 'Luhtahuitti',
    nameLatin: 'Porzana porzana',
    nameEn: 'Spotted Crake',
    rarity: 3,
    photo: 'luhtahuitti.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/luhtahuitti'
  },
  {
    id: 'b-61',
    orderLatin: 'Gruiformes',
    orderFi: 'Kurkilinnut',
    familyLatin: 'Rallidae',
    familyFi: 'Rantakanat',
    nameFi: 'Ruisrääkkä',
    nameLatin: 'Crex crex',
    nameEn: 'Corn Crake',
    rarity: 2,
    photo: 'ruisraakka.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/ruisraakka'
  },
  {
    id: 'b-62',
    orderLatin: 'Gruiformes',
    orderFi: 'Kurkilinnut',
    familyLatin: 'Rallidae',
    familyFi: 'Rantakanat',
    nameFi: 'Liejukana',
    nameLatin: 'Gallinula chloropus',
    nameEn: 'Common Moorhen',
    rarity: 2,
    photo: 'liejukana.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/liejukana'
  },
  {
    id: 'b-63',
    orderLatin: 'Gruiformes',
    orderFi: 'Kurkilinnut',
    familyLatin: 'Rallidae',
    familyFi: 'Rantakanat',
    nameFi: 'Nokikana',
    nameLatin: 'Fulica atra',
    nameEn: 'Eurasian Coot',
    rarity: 2,
    photo: 'nokikana.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/nokikana'
  },
  {
    id: 'b-64',
    orderLatin: 'Gruiformes',
    orderFi: 'Kurkilinnut',
    familyLatin: 'Gruidae',
    familyFi: 'Kurjet',
    nameFi: 'Kurki',
    nameLatin: 'Grus grus',
    nameEn: 'Common Crane',
    rarity: 1,
    photo: 'kurki.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kurki'
  },
  {
    id: 'b-65',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Haematopodidae',
    familyFi: 'Meriharakat',
    nameFi: 'Meriharakka',
    nameLatin: 'Haematopus ostralegus',
    nameEn: 'Eurasian Oystercatcher',
    rarity: 2,
    photo: 'meriharakka.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/meriharakka'
  },
  {
    id: 'b-66',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Charadriidae',
    familyFi: 'Kurmitsat',
    nameFi: 'Kapustarinta',
    nameLatin: 'Pluvialis apricaria',
    nameEn: 'European Golden Plover',
    rarity: 2,
    photo: 'kapustarinta.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kapustarinta'
  },
  {
    id: 'b-67',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Charadriidae',
    familyFi: 'Kurmitsat',
    nameFi: 'Töyhtöhyyppä',
    nameLatin: 'Vanellus vanellus',
    nameEn: 'Northern Lapwing',
    rarity: 1,
    photo: 'toyhtohyyppa.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/toyhtohyyppa'
  },
  {
    id: 'b-68',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Charadriidae',
    familyFi: 'Kurmitsat',
    nameFi: 'Tylli',
    nameLatin: 'Charadrius hiaticula',
    nameEn: 'Common Ringed Plover',
    rarity: 3,
    photo: 'tylli.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/tylli'
  },
  {
    id: 'b-69',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Charadriidae',
    familyFi: 'Kurmitsat',
    nameFi: 'Pikkutylli',
    nameLatin: 'Charadrius dubius',
    nameEn: 'Little Ringed Plover',
    rarity: 3,
    photo: 'pikkutylli.jpg',
    photographer: 'Eetu Hurtig',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pikkutylli'
  },
  {
    id: 'b-70',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Charadriidae',
    familyFi: 'Kurmitsat',
    nameFi: 'Pikkukuovi',
    nameLatin: 'Numenius phaeopus',
    nameEn: 'Whimbrel',
    rarity: 3,
    photo: 'pikkukuovi.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pikkukuovi'
  },
  {
    id: 'b-71',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Kuovi',
    nameLatin: 'Numenius arquata',
    nameEn: 'Eurasian Curlew',
    rarity: 2,
    photo: 'kuovi.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kuovi'
  },
  {
    id: 'b-72',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Mustapyrstökuiri',
    nameLatin: 'Limosa limosa',
    nameEn: 'Black-tailed Godwit',
    rarity: 3,
    photo: 'mustapyrstokuiri.jpg',
    photographer: 'Henrikki Hetemaa',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/mustapyrstokuiri'
  },
  {
    id: 'b-73',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Karikukko',
    nameLatin: 'Arenaria interpres',
    nameEn: 'Ruddy Turnstone',
    rarity: 3,
    photo: 'karikukko.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/karikukko'
  },
  {
    id: 'b-74',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Suokukko',
    nameLatin: 'Calidris pugnax',
    nameEn: 'Ruff',
    rarity: 2,
    photo: 'suokukko.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/suokukko'
  },
  {
    id: 'b-75',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Pulmussirri',
    nameLatin: 'Calidris alba',
    nameEn: 'Sanderling',
    rarity: 3,
    photo: 'pulmussirri.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pulmussirri'
  },
  {
    id: 'b-76',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Suosirri',
    nameLatin: 'Calidris alpina',
    nameEn: 'Dunlin',
    rarity: 3,
    photo: 'suosirri.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/suosirri'
  },
  {
    id: 'b-77',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Rantasipi',
    nameLatin: 'Actitis hypoleucos',
    nameEn: 'Common Sandpiper',
    rarity: 2,
    photo: 'rantasipi.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/rantasipi'
  },
  {
    id: 'b-78',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Metsäviklo',
    nameLatin: 'Tringa ochropus',
    nameEn: 'Green Sandpiper',
    rarity: 2,
    photo: 'metsaviklo.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/metsaviklo'
  },
  {
    id: 'b-79',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Valkoviklo',
    nameLatin: 'Tringa nebularia',
    nameEn: 'Common Greenshank',
    rarity: 2,
    photo: 'valkoviklo.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/valkoviklo'
  },
  {
    id: 'b-80',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Punajalkaviklo',
    nameLatin: 'Tringa totanus',
    nameEn: 'Common Redshank',
    rarity: 3,
    photo: 'punajalkaviklo.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/punajalkaviklo'
  },
  {
    id: 'b-81',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Jänkäkurppa',
    nameLatin: 'Lymnocryptes minimus',
    nameEn: 'Jack Snipe',
    rarity: 3,
    photo: 'jankakurppa.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/jankakurppa'
  },
  {
    id: 'b-82',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Liro',
    nameLatin: 'Tringa glareola',
    nameEn: 'Wood Sandpiper',
    rarity: 2,
    photo: 'liro.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/liro'
  },
  {
    id: 'b-83',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Lehtokurppa',
    nameLatin: 'Scolopax rusticola',
    nameEn: 'Eurasian Woodcock',
    rarity: 2,
    photo: 'lehtokurppa.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/lehtokurppa'
  },
  {
    id: 'b-84',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Taivaanvuohi',
    nameLatin: 'Gallinago gallinago',
    nameEn: 'Common Snipe',
    rarity: 2,
    photo: 'taivaanvuohi.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/taivaanvuohi'
  },
  {
    id: 'b-85',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Stercorariidae',
    familyFi: 'Kihut',
    nameFi: 'Tunturikihu',
    nameLatin: 'Stercorarius longicaudus',
    nameEn: 'Long-tailed Jaeger',
    rarity: 4,
    photo: 'tunturikihu.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/tunturikihu'
  },
  {
    id: 'b-86',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Sternidae',
    familyFi: 'Tiirat',
    nameFi: 'Räyskä',
    nameLatin: 'Hydroprogne caspia',
    nameEn: 'Caspian Tern',
    rarity: 3,
    photo: 'rayska.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/rayska'
  },
  {
    id: 'b-87',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Sternidae',
    familyFi: 'Tiirat',
    nameFi: 'Kalatiira',
    nameLatin: 'Sterna hirundo',
    nameEn: 'Common Tern',
    rarity: 2,
    photo: 'kalatiira.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kalatiira'
  },
  {
    id: 'b-88',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Sternidae',
    familyFi: 'Tiirat',
    nameFi: 'Lapintiira',
    nameLatin: 'Sterna paradisaea',
    nameEn: 'Arctic Tern',
    rarity: 2,
    photo: 'lapintiira.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/lapintiira'
  },
  {
    id: 'b-89',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Laridae',
    familyFi: 'Lokit',
    nameFi: 'Pikkulokki',
    nameLatin: 'Hydrocoloeus minutus',
    nameEn: 'Little Gull',
    rarity: 2,
    photo: 'pikkulokki.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pikkulokki'
  },
  {
    id: 'b-90',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Laridae',
    familyFi: 'Lokit',
    nameFi: 'Naurulokki',
    nameLatin: 'Larus ridibundus',
    nameEn: 'Black-headed Gull',
    rarity: 1,
    photo: 'naurulokki.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/naurulokki'
  },
  {
    id: 'b-91',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Laridae',
    familyFi: 'Lokit',
    nameFi: 'Kalalokki',
    nameLatin: 'Larus canus',
    nameEn: 'Mew Gull',
    rarity: 1,
    photo: 'kalalokki.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kalalokki'
  },
  {
    id: 'b-92',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Laridae',
    familyFi: 'Lokit',
    nameFi: 'Selkälokki',
    nameLatin: 'Larus fuscus',
    nameEn: 'Lesser Black-backed Gull',
    rarity: 2,
    photo: 'selkalokki.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/selkalokki'
  },
  {
    id: 'b-93',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Laridae',
    familyFi: 'Lokit',
    nameFi: 'Harmaalokki',
    nameLatin: 'Larus argentatus',
    nameEn: 'European Herring Gull',
    rarity: 1,
    photo: 'harmaalokki.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/harmaalokki'
  },
  {
    id: 'b-94',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Laridae',
    familyFi: 'Lokit',
    nameFi: 'Merilokki',
    nameLatin: 'Larus marinus',
    nameEn: 'Great Black-backed Gull',
    rarity: 2,
    photo: 'merilokki.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/merilokki'
  },
  {
    id: 'b-95',
    orderLatin: 'Columbiformes',
    orderFi: 'Kyyhkylinnut',
    familyLatin: 'Columbidae',
    familyFi: 'Kyyhkyt',
    nameFi: 'Kesykyyhky',
    nameLatin: 'Columba livia domestica',
    nameEn: 'Feral Pigeon',
    rarity: 1,
    photo: 'kesykyyhky.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kesykyyhky'
  },
  {
    id: 'b-96',
    orderLatin: 'Columbiformes',
    orderFi: 'Kyyhkylinnut',
    familyLatin: 'Columbidae',
    familyFi: 'Kyyhkyt',
    nameFi: 'Uuttukyyhky',
    nameLatin: 'Columba oenas',
    nameEn: 'Stock Dove',
    rarity: 3,
    photo: 'uuttukyyhky.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/uuttukyyhky'
  },
  {
    id: 'b-97',
    orderLatin: 'Columbiformes',
    orderFi: 'Kyyhkylinnut',
    familyLatin: 'Columbidae',
    familyFi: 'Kyyhkyt',
    nameFi: 'Turkinkyyhky',
    nameLatin: 'Streptopelia decaocto',
    nameEn: 'Eurasian Collared Dove',
    rarity: 3,
    photo: 'turkinkyyhky.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/turkinkyyhky'
  },
  {
    id: 'b-98',
    orderLatin: 'Columbiformes',
    orderFi: 'Kyyhkylinnut',
    familyLatin: 'Columbidae',
    familyFi: 'Kyyhkyt',
    nameFi: 'Idänturturikyyhky',
    nameLatin: 'Streptopelia orientalis',
    nameEn: 'Oriental Turtle Dove',
    rarity: 5,
    photo: 'idanturturikyyhky.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://fi.wikipedia.org/wiki/Idänturturikyyhky'
  },
  {
    id: 'b-99',
    orderLatin: 'Columbiformes',
    orderFi: 'Kyyhkylinnut',
    familyLatin: 'Columbidae',
    familyFi: 'Kyyhkyt',
    nameFi: 'Sepelkyyhky',
    nameLatin: 'Columba palumbus',
    nameEn: 'Common Wood Pigeon',
    rarity: 1,
    photo: 'sepelkyyhky.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/sepelkyyhky'
  },
  {
    id: 'b-100',
    orderLatin: 'Cuculiformes',
    orderFi: 'Käkilinnut',
    familyLatin: 'Cuculidae',
    familyFi: 'Käet',
    nameFi: 'Käki',
    nameLatin: 'Cuculus canorus',
    nameEn: 'Common Cuckoo',
    rarity: 2,
    photo: 'kaki.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kaki'
  },
  {
    id: 'b-101',
    orderLatin: 'Strigiformes',
    orderFi: 'Pöllölinnut',
    familyLatin: 'Strigidae',
    familyFi: 'Pöllöt',
    nameFi: 'Huuhkaja',
    nameLatin: 'Bubo bubo',
    nameEn: 'Eurasian Eagle Owl',
    rarity: 3,
    photo: 'huuhkaja.jpg',
    photographer: 'pixabay.com',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/huuhkaja'
  },
  {
    id: 'b-102',
    orderLatin: 'Strigiformes',
    orderFi: 'Pöllölinnut',
    familyLatin: 'Strigidae',
    familyFi: 'Pöllöt',
    nameFi: 'Hiiripöllö',
    nameLatin: 'Surnia ulula',
    nameEn: 'Northern Hawk Owl',
    rarity: 3,
    photo: 'hiiripollo.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/hiiripollo'
  },
  {
    id: 'b-103',
    orderLatin: 'Strigiformes',
    orderFi: 'Pöllölinnut',
    familyLatin: 'Strigidae',
    familyFi: 'Pöllöt',
    nameFi: 'Varpuspöllö',
    nameLatin: 'Glaucidium passerinum',
    nameEn: 'Eurasian Pygmy Owl',
    rarity: 3,
    photo: 'varpuspollo.jpg',
    photographer: 'pixabay.com',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/varpuspollo'
  },
  {
    id: 'b-104',
    orderLatin: 'Strigiformes',
    orderFi: 'Pöllölinnut',
    familyLatin: 'Strigidae',
    familyFi: 'Pöllöt',
    nameFi: 'Lehtopöllö',
    nameLatin: 'Strix aluco',
    nameEn: 'Tawny Owl',
    rarity: 3,
    photo: 'lehtopollo.jpg',
    photographer: 'pixabay.com',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/lehtopollo'
  },
  {
    id: 'b-105',
    orderLatin: 'Strigiformes',
    orderFi: 'Pöllölinnut',
    familyLatin: 'Strigidae',
    familyFi: 'Pöllöt',
    nameFi: 'Viirupöllö',
    nameLatin: 'Strix uralensis',
    nameEn: 'Ural Owl',
    rarity: 3,
    photo: 'viirupollo.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/viirupollo'
  },
  {
    id: 'b-106',
    orderLatin: 'Strigiformes',
    orderFi: 'Pöllölinnut',
    familyLatin: 'Strigidae',
    familyFi: 'Pöllöt',
    nameFi: 'Lapinpöllö',
    nameLatin: 'Strix nebulosa',
    nameEn: 'Great Grey Owl',
    rarity: 3,
    photo: 'lapinpollo.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/lapinpollo'
  },
  {
    id: 'b-107',
    orderLatin: 'Strigiformes',
    orderFi: 'Pöllölinnut',
    familyLatin: 'Strigidae',
    familyFi: 'Pöllöt',
    nameFi: 'Sarvipöllö',
    nameLatin: 'Asio otus',
    nameEn: 'Long-eared Owl',
    rarity: 3,
    photo: 'sarvipollo.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/sarvipollo'
  },
  {
    id: 'b-108',
    orderLatin: 'Strigiformes',
    orderFi: 'Pöllölinnut',
    familyLatin: 'Strigidae',
    familyFi: 'Pöllöt',
    nameFi: 'Suopöllö',
    nameLatin: 'Asio flammeus',
    nameEn: 'Short-eared Owl',
    rarity: 3,
    photo: 'suopollo.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/suopollo'
  },
  {
    id: 'b-109',
    orderLatin: 'Strigiformes',
    orderFi: 'Pöllölinnut',
    familyLatin: 'Strigidae',
    familyFi: 'Pöllöt',
    nameFi: 'Helmipöllö',
    nameLatin: 'Aegolius funereus',
    nameEn: 'Boreal Owl',
    rarity: 3,
    photo: 'helmipollo.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/helmipollo'
  },
  {
    id: 'b-110',
    orderLatin: 'Caprimulgiformes',
    orderFi: 'Kehrääjälinnut',
    familyLatin: 'Caprimulgidae',
    familyFi: 'Kehrääjät',
    nameFi: 'Kehrääjä',
    nameLatin: 'Caprimulgus europaeus',
    nameEn: 'European Nightjar',
    rarity: 2,
    photo: 'kehraaja.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kehraaja'
  },
  {
    id: 'b-111',
    orderLatin: 'Apodiformes',
    orderFi: 'Kirskulinnut',
    familyLatin: 'Apodidae',
    familyFi: 'Kiitäjät',
    nameFi: 'Tervapääsky',
    nameLatin: 'Apus apus',
    nameEn: 'Common Swift',
    rarity: 1,
    photo: 'tervapaasky.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/tervapaasky'
  },
  {
    id: 'b-112',
    orderLatin: 'Coraciiformes',
    orderFi: 'Säihkylinnut',
    familyLatin: 'Alcedinidae',
    familyFi: 'Kuningaskalastajat',
    nameFi: 'Kuningaskalastaja',
    nameLatin: 'Alcedo atthis',
    nameEn: 'Common Kingfisher',
    rarity: 5,
    photo: 'kuningaskalastaja.jpg',
    photographer: 'pixabay.com',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kuningaskalastaja'
  },
  {
    id: 'b-113',
    orderLatin: 'Coraciiformes',
    orderFi: 'Säihkylinnut',
    familyLatin: 'Upupidae',
    familyFi: 'Harjalinnut',
    nameFi: 'Harjalintu',
    nameLatin: 'Upupa epops',
    nameEn: 'Hoopoe',
    rarity: 4,
    photo: 'harjalintu.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/harjalintu'
  },
  {
    id: 'b-114',
    orderLatin: 'Piciformes',
    orderFi: 'Tikkalinnut',
    familyLatin: 'Picidae',
    familyFi: 'Tikat',
    nameFi: 'Käenpiika',
    nameLatin: 'Jynx torquilla',
    nameEn: 'Eurasian Wryneck',
    rarity: 3,
    photo: 'kaenpiika.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kaenpiika'
  },
  {
    id: 'b-115',
    orderLatin: 'Piciformes',
    orderFi: 'Tikkalinnut',
    familyLatin: 'Picidae',
    familyFi: 'Tikat',
    nameFi: 'Harmaapäätikka',
    nameLatin: 'Picus canus',
    nameEn: 'Grey-headed Woodpecker',
    rarity: 3,
    photo: 'harmaapaatikka.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/harmaapaatikka'
  },
  {
    id: 'b-116',
    orderLatin: 'Piciformes',
    orderFi: 'Tikkalinnut',
    familyLatin: 'Picidae',
    familyFi: 'Tikat',
    nameFi: 'Palokärki',
    nameLatin: 'Dryocopus martius',
    nameEn: 'Black Woodpecker',
    rarity: 2,
    photo: 'palokarki.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/palokarki'
  },
  {
    id: 'b-117',
    orderLatin: 'Piciformes',
    orderFi: 'Tikkalinnut',
    familyLatin: 'Picidae',
    familyFi: 'Tikat',
    nameFi: 'Käpytikka',
    nameLatin: 'Dendrocopos major',
    nameEn: 'Great Spotted Woodpecker',
    rarity: 1,
    photo: 'kapytikka.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kapytikka'
  },
  {
    id: 'b-118',
    orderLatin: 'Piciformes',
    orderFi: 'Tikkalinnut',
    familyLatin: 'Picidae',
    familyFi: 'Tikat',
    nameFi: 'Valkoselkätikka',
    nameLatin: 'Dendrocopos leucotos',
    nameEn: 'White-backed Woodpecker',
    rarity: 4,
    photo: 'valkoselkatikka.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/valkoselkatikka'
  },
  {
    id: 'b-119',
    orderLatin: 'Piciformes',
    orderFi: 'Tikkalinnut',
    familyLatin: 'Picidae',
    familyFi: 'Tikat',
    nameFi: 'Pikkutikka',
    nameLatin: 'Dendrocopos minor',
    nameEn: 'Lesser Spotted Woodpecker',
    rarity: 3,
    photo: 'pikkutikka.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pikkutikka'
  },
  {
    id: 'b-120',
    orderLatin: 'Piciformes',
    orderFi: 'Tikkalinnut',
    familyLatin: 'Picidae',
    familyFi: 'Tikat',
    nameFi: 'Pohjantikka',
    nameLatin: 'Picoides tridactylus',
    nameEn: 'Three-toed Woodpecker',
    rarity: 3,
    photo: 'pohjantikka.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pohjantikka'
  },
  {
    id: 'b-121',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Alaudidae',
    familyFi: 'Kiurut',
    nameFi: 'Kiuru',
    nameLatin: 'Alauda arvensis',
    nameEn: 'Eurasian Skylark',
    rarity: 2,
    photo: 'kiuru.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kiuru'
  },
  {
    id: 'b-122',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Hirundinidae',
    familyFi: 'Pääskyt',
    nameFi: 'Törmäpääsky',
    nameLatin: 'Riparia riparia',
    nameEn: 'Sand Martin',
    rarity: 3,
    photo: 'tormapaasky.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/tormapaasky'
  },
  {
    id: 'b-123',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Hirundinidae',
    familyFi: 'Pääskyt',
    nameFi: 'Haarapääsky',
    nameLatin: 'Hirundo rustica',
    nameEn: 'Barn Swallow',
    rarity: 2,
    photo: 'haarapaasky.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/haarapaasky'
  },
  {
    id: 'b-124',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Hirundinidae',
    familyFi: 'Pääskyt',
    nameFi: 'Räystäspääsky',
    nameLatin: 'Delichon urbicum',
    nameEn: 'Common House Martin',
    rarity: 2,
    photo: 'raystaspaasky.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/raystaspaasky'
  },
  {
    id: 'b-125',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Motacillidae',
    familyFi: 'Västäräkit',
    nameFi: 'Metsäkirvinen',
    nameLatin: 'Anthus trivialis',
    nameEn: 'Tree Pipit',
    rarity: 2,
    photo: 'metsakirvinen.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/metsakirvinen'
  },
  {
    id: 'b-126',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Motacillidae',
    familyFi: 'Västäräkit',
    nameFi: 'Niittykirvinen',
    nameLatin: 'Anthus pratensis',
    nameEn: 'Meadow Pipit',
    rarity: 2,
    photo: 'niittykirvinen.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/niittykirvinen'
  },
  {
    id: 'b-127',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Motacillidae',
    familyFi: 'Västäräkit',
    nameFi: 'Keltavästäräkki',
    nameLatin: 'Motacilla flava',
    nameEn: 'Yellow Wagtail',
    rarity: 3,
    photo: 'keltavastarakki.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/keltavastarakki'
  },
  {
    id: 'b-128',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Motacillidae',
    familyFi: 'Västäräkit',
    nameFi: 'Västäräkki',
    nameLatin: 'Motacilla alba',
    nameEn: 'White Wagtail',
    rarity: 1,
    photo: 'vastarakki.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/vastarakki'
  },
  {
    id: 'b-129',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Bombycillidae',
    familyFi: 'Tilhet',
    nameFi: 'Tilhi',
    nameLatin: 'Bombycilla garrulus',
    nameEn: 'Bohemian Waxwing',
    rarity: 2,
    photo: 'tilhi.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/tilhi'
  },
  {
    id: 'b-130',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Cinclidae',
    familyFi: 'Koskikarat',
    nameFi: 'Koskikara',
    nameLatin: 'Cinclus cinclus',
    nameEn: 'White-throated Dipper',
    rarity: 2,
    photo: 'koskikara.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/koskikara'
  },
  {
    id: 'b-131',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Troglodytidae',
    familyFi: 'Peukaloiset',
    nameFi: 'Peukaloinen',
    nameLatin: 'Troglodytes troglodytes',
    nameEn: 'Winter Wren',
    rarity: 2,
    photo: 'peukaloinen.jpg',
    photographer: 'Liam Sanchez',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/peukaloinen'
  },
  {
    id: 'b-132',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Troglodytidae',
    familyFi: 'Peukaloiset',
    nameFi: 'Rautiainen',
    nameLatin: 'Prunella modularis',
    nameEn: 'Dunnock',
    rarity: 2,
    photo: 'rautiainen.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/rautiainen'
  },
  {
    id: 'b-133',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Turdidae',
    familyFi: 'Rastaat',
    nameFi: 'Mustarastas',
    nameLatin: 'Turdus merula',
    nameEn: 'Common Blackbird',
    rarity: 1,
    photo: 'mustarastas.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/mustarastas'
  },
  {
    id: 'b-134',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Turdidae',
    familyFi: 'Rastaat',
    nameFi: 'Räkättirastas',
    nameLatin: 'Turdus pilaris',
    nameEn: 'Fieldfare',
    rarity: 1,
    photo: 'rakattirastas.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/rakattirastas'
  },
  {
    id: 'b-135',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Turdidae',
    familyFi: 'Rastaat',
    nameFi: 'Laulurastas',
    nameLatin: 'Turdus philomelos',
    nameEn: 'Song Thrush',
    rarity: 2,
    photo: 'laulurastas.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/laulurastas'
  },
  {
    id: 'b-136',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Turdidae',
    familyFi: 'Rastaat',
    nameFi: 'Punakylkirastas',
    nameLatin: 'Turdus iliacus',
    nameEn: 'Redwing',
    rarity: 1,
    photo: 'punakylkirastas.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/punakylkirastas'
  },
  {
    id: 'b-137',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Turdidae',
    familyFi: 'Rastaat',
    nameFi: 'Kulorastas',
    nameLatin: 'Turdus viscivorus',
    nameEn: 'Mistle Thrush',
    rarity: 2,
    photo: 'kulorastas.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kulorastas'
  },
  {
    id: 'b-138',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Locustellidae',
    familyFi: 'Sirkkalinnut',
    nameFi: 'Pensassirkkalintu',
    nameLatin: 'Locustella naevia',
    nameEn: 'Common Grasshopper Warbler',
    rarity: 3,
    photo: 'pensassirkkalintu.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://fi.wikipedia.org/wiki/Pensassirkkalintu'
  },
  {
    id: 'b-139',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Hippolais',
    familyFi: 'Kultarinnat',
    nameFi: 'Kultarinta',
    nameLatin: 'Hippolais icterina',
    nameEn: 'Icterine Warbler',
    rarity: 3,
    photo: 'kultarinta.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kultarinta'
  },
  {
    id: 'b-140',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Acrocephalidae',
    familyFi: 'Kerttuset',
    nameFi: 'Ruokokerttunen',
    nameLatin: 'Acrocephalus schoenobaenus',
    nameEn: 'Sedge Warbler',
    rarity: 3,
    photo: 'ruokokerttunen.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/ruokokerttunen'
  },
  {
    id: 'b-141',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Acrocephalidae',
    familyFi: 'Kerttuset',
    nameFi: 'Viitakerttunen',
    nameLatin: 'Acrocephalus dumetorum',
    nameEn: "Blyth's Reed Warbler",
    rarity: 3,
    photo: 'viitakerttunen.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://fi.wikipedia.org/wiki/Viitakerttunen'
  },
  {
    id: 'b-142',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Acrocephalidae',
    familyFi: 'Kerttuset',
    nameFi: 'Luhtakerttunen',
    nameLatin: 'Acrocephalus palustris',
    nameEn: 'Marsh Warbler',
    rarity: 3,
    photo: 'luhtakerttunen.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://fi.wikipedia.org/wiki/Luhtakerttunen'
  },
  {
    id: 'b-143',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Acrocephalidae',
    familyFi: 'Kerttuset',
    nameFi: 'Rytikerttunen',
    nameLatin: 'Acrocephalus scirpaceus',
    nameEn: 'Eurasian Reed Warbler',
    rarity: 3,
    photo: 'rytikerttunen.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://fi.wikipedia.org/wiki/Rytikerttunen'
  },
  {
    id: 'b-144',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Sylviidae',
    familyFi: 'Kertut',
    nameFi: 'Hernekerttu',
    nameLatin: 'Sylvia curruca',
    nameEn: 'Lesser Whitethroat',
    rarity: 3,
    photo: 'hernekerttu.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/hernekerttu'
  },
  {
    id: 'b-145',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Sylviidae',
    familyFi: 'Kertut',
    nameFi: 'Pensaskerttu',
    nameLatin: 'Sylvia communis',
    nameEn: 'Common Whitethroat',
    rarity: 3,
    photo: 'pensaskerttu.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pensaskerttu'
  },
  {
    id: 'b-146',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Sylviidae',
    familyFi: 'Kertut',
    nameFi: 'Lehtokerttu',
    nameLatin: 'Sylvia borin',
    nameEn: 'Garden Warbler',
    rarity: 3,
    photo: 'lehtokerttu.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/lehtokerttu'
  },
  {
    id: 'b-147',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Sylviidae',
    familyFi: 'Kertut',
    nameFi: 'Mustapääkerttu',
    nameLatin: 'Sylvia atricapilla',
    nameEn: 'Eurasian Blackcap',
    rarity: 3,
    photo: 'mustapaakerttu.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/mustapaakerttu'
  },
  {
    id: 'b-148',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Phylloscopidae',
    familyFi: 'Uulinnut',
    nameFi: 'Sirittäjä',
    nameLatin: 'Phylloscopus sibilatrix',
    nameEn: 'Wood Warbler',
    rarity: 3,
    photo: 'sirittaja.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://fi.wikipedia.org/wiki/Sirittäjä'
  },
  {
    id: 'b-149',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Phylloscopidae',
    familyFi: 'Uulinnut',
    nameFi: 'Tiltaltti',
    nameLatin: 'Phylloscopus collybita',
    nameEn: 'Common Chiffchaff',
    rarity: 2,
    photo: 'tiltaltti.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/tiltaltti'
  },
  {
    id: 'b-150',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Phylloscopidae',
    familyFi: 'Uulinnut',
    nameFi: 'Taigauunilintu',
    nameLatin: 'Phylloscopus inornatus',
    nameEn: 'Yellow-browed Warbler',
    rarity: 5,
    photo: 'taigauunilintu.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://fi.wikipedia.org/wiki/Taigauunilintu'
  },
  {
    id: 'b-151',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Phylloscopidae',
    familyFi: 'Uunilinnut',
    nameFi: 'Pajulintu',
    nameLatin: 'Phylloscopus trochilus',
    nameEn: 'Willow Warbler',
    rarity: 2,
    photo: 'pajulintu.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pajulintu'
  },
  {
    id: 'b-152',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Regulidae',
    familyFi: 'Hippiäiset',
    nameFi: 'Hippiäinen',
    nameLatin: 'Regulus regulus',
    nameEn: 'Goldcrest',
    rarity: 2,
    photo: 'hippiainen.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/hippiainen'
  },
  {
    id: 'b-153',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Muscicapidae',
    familyFi: 'Siepot',
    nameFi: 'Harmaasieppo',
    nameLatin: 'Muscicapa striata',
    nameEn: 'Spotted Flycatcher',
    rarity: 2,
    photo: 'harmaasieppo.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/harmaasieppo'
  },
  {
    id: 'b-154',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Muscicapidae',
    familyFi: 'Siepot',
    nameFi: 'Pikkusieppo',
    nameLatin: 'Ficedula parva',
    nameEn: 'Red-breasted Flycatcher',
    rarity: 3,
    photo: 'pikkusieppo.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://fi.wikipedia.org/wiki/Pikkusieppo'
  },
  {
    id: 'b-155',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Muscicapidae',
    familyFi: 'Siepot',
    nameFi: 'Kirjosieppo',
    nameLatin: 'Ficedula hypoleuca',
    nameEn: 'European Pied Flycatcher',
    rarity: 2,
    photo: 'kirjosieppo.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kirjosieppo'
  },
  {
    id: 'b-156',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Muscicapidae',
    familyFi: 'Siepot',
    nameFi: 'Punarinta',
    nameLatin: 'Erithacus rubecula',
    nameEn: 'European Robin',
    rarity: 1,
    photo: 'punarinta.jpg',
    photographer: 'pixabay.com',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/punarinta'
  },
  {
    id: 'b-157',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Muscicapidae',
    familyFi: 'Siepot',
    nameFi: 'Satakieli',
    nameLatin: 'Luscinia luscinia',
    nameEn: 'Thrush Nightingale',
    rarity: 2,
    photo: 'satakieli.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/satakieli'
  },
  {
    id: 'b-158',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Muscicapidae',
    familyFi: 'Siepot',
    nameFi: 'Sinirinta',
    nameLatin: 'Luscinia svecica',
    nameEn: 'Bluethroat',
    rarity: 3,
    photo: 'sinirinta.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/sinirinta'
  },
  {
    id: 'b-159',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Muscicapidae',
    familyFi: 'Siepot',
    nameFi: 'Sinipyrstö',
    nameLatin: 'Tarsiger cyanurus',
    nameEn: 'Red-flanked Bluetail',
    rarity: 4,
    photo: 'sinipyrsto.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/sinipyrsto'
  },
  {
    id: 'b-160',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Muscicapidae',
    familyFi: 'Siepot',
    nameFi: 'Leppälintu',
    nameLatin: 'Phoenicurus phoenicurus',
    nameEn: 'Common Redstart',
    rarity: 2,
    photo: 'leppalintu.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/leppalintu'
  },
  {
    id: 'b-161',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Muscicapidae',
    familyFi: 'Siepot',
    nameFi: 'Pensastasku',
    nameLatin: 'Saxicola rubetra',
    nameEn: 'Whinchat',
    rarity: 2,
    photo: 'pensastasku.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pensastasku'
  },
  {
    id: 'b-162',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Muscicapidae',
    familyFi: 'Siepot',
    nameFi: 'Kivitasku',
    nameLatin: 'Oenanthe oenanthe',
    nameEn: 'Northern Wheatear',
    rarity: 2,
    photo: 'kivitasku.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kivitasku'
  },
  {
    id: 'b-163',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Panuridae',
    familyFi: 'Viiksitimalit',
    nameFi: 'Viiksitimali',
    nameLatin: 'Panurus biarmicus',
    nameEn: 'Bearded Reedling',
    rarity: 4,
    photo: 'viiksitimali.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/viiksitimali'
  },
  {
    id: 'b-164',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Aegithalidae',
    familyFi: 'Pyrstötiaiset',
    nameFi: 'Pyrstötiainen',
    nameLatin: 'Aegithalos caudatus',
    nameEn: 'Long-tailed Tit',
    rarity: 2,
    photo: 'pyrstotiainen.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pyrstotiainen'
  },
  {
    id: 'b-165',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Paridae',
    familyFi: 'Tiaiset',
    nameFi: 'Sinitiainen',
    nameLatin: 'Cyanistes caeruleus',
    nameEn: 'Eurasian Blue Tit',
    rarity: 1,
    photo: 'sinitiainen.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/sinitiainen'
  },
  {
    id: 'b-166',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Paridae',
    familyFi: 'Tiaiset',
    nameFi: 'Talitiainen',
    nameLatin: 'Parus major',
    nameEn: 'Great Tit',
    rarity: 1,
    photo: 'talitiainen.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/talitiainen'
  },
  {
    id: 'b-167',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Paridae',
    familyFi: 'Tiaiset',
    nameFi: 'Kuusitiainen',
    nameLatin: 'Periparus ater',
    nameEn: 'Coal Tit',
    rarity: 2,
    photo: 'kuusitiainen.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kuusitiainen'
  },
  {
    id: 'b-168',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Paridae',
    familyFi: 'Tiaiset',
    nameFi: 'Töyhtötiainen',
    nameLatin: 'Lophophanes cristatus',
    nameEn: 'European Crested Tit',
    rarity: 2,
    photo: 'toyhtotiainen.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/toyhtotiainen'
  },
  {
    id: 'b-169',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Paridae',
    familyFi: 'Tiaiset',
    nameFi: 'Hömötiainen',
    nameLatin: 'Poecile montanus',
    nameEn: 'Willow Tit',
    rarity: 2,
    photo: 'homotiainen.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/homotiainen'
  },
  {
    id: 'b-170',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Paridae',
    familyFi: 'Tiaiset',
    nameFi: 'Lapintiainen',
    nameLatin: 'Poecile cinctus',
    nameEn: 'Grey-headed Chickadee',
    rarity: 2,
    photo: 'lapintiainen.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/lapintiainen'
  },
  {
    id: 'b-171',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Sittidae',
    familyFi: 'Pähkinänakkelit',
    nameFi: 'Pähkinänakkeli',
    nameLatin: 'Sitta europaea',
    nameEn: 'Eurasian Nuthatch',
    rarity: 3,
    photo: 'pahkinanakkeli.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pahkinanakkeli'
  },
  {
    id: 'b-172',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Certhiidae',
    familyFi: 'Puukiipijät',
    nameFi: 'Puukiipijä',
    nameLatin: 'Certhia familiaris',
    nameEn: 'Eurasian Treecreeper',
    rarity: 2,
    photo: 'puukiipija.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/puukiipija'
  },
  {
    id: 'b-173',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Laniidae',
    familyFi: 'Lepinkäiset',
    nameFi: 'Pikkulepinkäinen',
    nameLatin: 'Lanius collurio',
    nameEn: 'Red-backed Shrike',
    rarity: 3,
    photo: 'pikkulepinkainen.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pikkulepinkainen'
  },
  {
    id: 'b-174',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Laniidae',
    familyFi: 'Lepinkäiset',
    nameFi: 'Isolepinkäinen',
    nameLatin: 'Lanius excubitor',
    nameEn: 'Great Grey Shrike',
    rarity: 2,
    photo: 'isolepinkainen.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/isolepinkainen'
  },
  {
    id: 'b-175',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Corvidae',
    familyFi: 'Varikset',
    nameFi: 'Närhi',
    nameLatin: 'Garrulus glandarius',
    nameEn: 'Eurasian Jay',
    rarity: 1,
    photo: 'narhi.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/narhi'
  },
  {
    id: 'b-176',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Corvidae',
    familyFi: 'Varikset',
    nameFi: 'Kuukkeli',
    nameLatin: 'Perisoreus infaustus',
    nameEn: 'Siberian Jay',
    rarity: 3,
    photo: 'kuukkeli.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kuukkeli'
  },
  {
    id: 'b-177',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Corvidae',
    familyFi: 'Varikset',
    nameFi: 'Harakka',
    nameLatin: 'Pica pica',
    nameEn: 'Common Magpie',
    rarity: 1,
    photo: 'harakka.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/harakka'
  },
  {
    id: 'b-178',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Corvidae',
    familyFi: 'Varikset',
    nameFi: 'Pähkinähakki',
    nameLatin: 'Nucifraga caryocatactes',
    nameEn: 'Spotted Nutcracker',
    rarity: 3,
    photo: 'pahkinahakki.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pahkinahakki'
  },
  {
    id: 'b-179',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Corvidae',
    familyFi: 'Varikset',
    nameFi: 'Naakka',
    nameLatin: 'Corvus monedula',
    nameEn: 'Western Jackdaw',
    rarity: 1,
    photo: 'naakka.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/naakka'
  },
  {
    id: 'b-180',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Corvidae',
    familyFi: 'Varikset',
    nameFi: 'Mustavaris',
    nameLatin: 'Corvus frugilegus',
    nameEn: 'Rook',
    rarity: 3,
    photo: 'mustavaris.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/mustavaris'
  },
  {
    id: 'b-181',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Corvidae',
    familyFi: 'Varikset',
    nameFi: 'Varis',
    nameLatin: 'Corvus corone cornix',
    nameEn: 'Hooded Crow',
    rarity: 1,
    photo: 'varis.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/varis'
  },
  {
    id: 'b-182',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Corvidae',
    familyFi: 'Varikset',
    nameFi: 'Korppi',
    nameLatin: 'Corvus corax',
    nameEn: 'Northern Raven',
    rarity: 1,
    photo: 'korppi.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/korppi'
  },
  {
    id: 'b-183',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Sturnidae',
    familyFi: 'Kottaraiset',
    nameFi: 'Kottarainen',
    nameLatin: 'Sturnus vulgaris',
    nameEn: 'Common Starling',
    rarity: 1,
    photo: 'kottarainen.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kottarainen'
  },
  {
    id: 'b-184',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Passeridae',
    familyFi: 'Varpuset',
    nameFi: 'Varpunen',
    nameLatin: 'Passer domesticus',
    nameEn: 'House Sparrow',
    rarity: 2,
    photo: 'varpunen.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/varpunen'
  },
  {
    id: 'b-185',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Passeridae',
    familyFi: 'Varpuset',
    nameFi: 'Pikkuvarpunen',
    nameLatin: 'Passer montanus',
    nameEn: 'Eurasian Tree Sparrow',
    rarity: 1,
    photo: 'pikkuvarpunen.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pikkuvarpunen'
  },
  {
    id: 'b-186',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Fringillidae',
    familyFi: 'Peipot',
    nameFi: 'Peippo',
    nameLatin: 'Fringilla coelebs',
    nameEn: 'Common Chaffinch',
    rarity: 1,
    photo: 'peippo.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/peippo'
  },
  {
    id: 'b-187',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Fringillidae',
    familyFi: 'Peipot',
    nameFi: 'Järripeippo',
    nameLatin: 'Fringilla montifringilla',
    nameEn: 'Brambling',
    rarity: 1,
    photo: 'jarripeippo.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/jarripeippo'
  },
  {
    id: 'b-188',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Fringillidae',
    familyFi: 'Peipot',
    nameFi: 'Viherpeippo',
    nameLatin: 'Carduelis chloris',
    nameEn: 'European Greenfinch',
    rarity: 1,
    photo: 'viherpeippo.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/viherpeippo'
  },
  {
    id: 'b-189',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Fringillidae',
    familyFi: 'Peipot',
    nameFi: 'Tikli',
    nameLatin: 'Carduelis carduelis',
    nameEn: 'European Goldfinch',
    rarity: 2,
    photo: 'tikli.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/tikli'
  },
  {
    id: 'b-190',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Fringillidae',
    familyFi: 'Peipot',
    nameFi: 'Vihervarpunen',
    nameLatin: 'Carduelis spinus',
    nameEn: 'Eurasian Siskin',
    rarity: 2,
    photo: 'vihervarpunen.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/vihervarpunen'
  },
  {
    id: 'b-191',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Fringillidae',
    familyFi: 'Peipot',
    nameFi: 'Hemppo',
    nameLatin: 'Carduelis cannabina',
    nameEn: 'Common Linnet',
    rarity: 2,
    photo: 'hemppo.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/hemppo'
  },
  {
    id: 'b-192',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Fringillidae',
    familyFi: 'Peipot',
    nameFi: 'Urpiainen',
    nameLatin: 'Carduelis flammea',
    nameEn: 'Common Redpoll',
    rarity: 1,
    photo: 'urpiainen.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/urpiainen'
  },
  {
    id: 'b-193',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Fringillidae',
    familyFi: 'Peipot',
    nameFi: 'Tundraurpiainen',
    nameLatin: 'Carduelis hornemanni',
    nameEn: 'Arctic Redpoll',
    rarity: 4,
    photo: 'tundraurpiainen.jpg',
    photographer: 'Henrikki Hetemaa',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/tundraurpiainen'
  },
  {
    id: 'b-194',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Fringillidae',
    familyFi: 'Peipot',
    nameFi: 'Pikkukäpylintu',
    nameLatin: 'Loxia curvirostra',
    nameEn: 'Red Crossbill',
    rarity: 2,
    photo: 'pikkukapylintu.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pikkukapylintu'
  },
  {
    id: 'b-195',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Fringillidae',
    familyFi: 'Peipot',
    nameFi: 'Isokäpylintu',
    nameLatin: 'Loxia pytyopsittacus',
    nameEn: 'Parrot Crossbill',
    rarity: 3,
    photo: 'isokapylintu.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/isokapylintu'
  },
  {
    id: 'b-196',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Fringillidae',
    familyFi: 'Peipot',
    nameFi: 'Punavarpunen',
    nameLatin: 'Carpodacus erythrinus',
    nameEn: 'Common Rosefinch',
    rarity: 2,
    photo: 'punavarpunen.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/punavarpunen'
  },
  {
    id: 'b-197',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Fringillidae',
    familyFi: 'Peipot',
    nameFi: 'Punatulkku',
    nameLatin: 'Pyrrhula pyrrhula',
    nameEn: 'Eurasian Bullfinch',
    rarity: 1,
    photo: 'punatulkku.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/punatulkku'
  },
  {
    id: 'b-198',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Fringillidae',
    familyFi: 'Peipot',
    nameFi: 'Nokkavarpunen',
    nameLatin: 'Coccothraustes coccothraustes',
    nameEn: 'Hawfinch',
    rarity: 3,
    photo: 'nokkavarpunen.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/nokkavarpunen'
  },
  {
    id: 'b-199',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Emberizidae',
    familyFi: 'Sirkut',
    nameFi: 'Pulmunen',
    nameLatin: 'Plectrophenax nivalis',
    nameEn: 'Snow Bunting',
    rarity: 3,
    photo: 'pulmunen.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pulmunen'
  },
  {
    id: 'b-200',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Emberizidae',
    familyFi: 'Sirkut',
    nameFi: 'Lapinsirkku',
    nameLatin: 'Calcarius lapponicus',
    nameEn: 'Lapland Longspur',
    rarity: 4,
    photo: 'lapinsirkku.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/lapinsirkku'
  },
  {
    id: 'b-201',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Emberizidae',
    familyFi: 'Sirkut',
    nameFi: 'Keltasirkku',
    nameLatin: 'Emberiza citrinella',
    nameEn: 'Yellowhammer',
    rarity: 1,
    photo: 'keltasirkku.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/keltasirkku'
  },
  {
    id: 'b-202',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Emberizidae',
    familyFi: 'Sirkut',
    nameFi: 'Peltosirkku',
    nameLatin: 'Emberiza hortulana',
    nameEn: 'Ortolan Bunting',
    rarity: 2,
    photo: 'peltosirkku.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/peltosirkku'
  },
  {
    id: 'b-203',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Emberizidae',
    familyFi: 'Sirkut',
    nameFi: 'Pajusirkku',
    nameLatin: 'Emberiza schoeniclus',
    nameEn: 'Common Reed Bunting',
    rarity: 2,
    photo: 'pajusirkku.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pajusirkku'
  },
  {
    id: 'b-204',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Kiljuhanhi',
    nameLatin: 'Anser erythropus',
    nameEn: 'Lesser White-fronted Goose',
    rarity: 5,
    photo: 'kiljuhanhi.jpg',
    photographer: 'Mette Skyttä',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kiljuhanhi'
  },
  {
    id: 'b-205',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Ristisorsa',
    nameLatin: 'Tadorna tadorna',
    nameEn: 'Common Shelduck',
    rarity: 4,
    photo: 'ristisorsa.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/ristisorsa'
  },
  {
    id: 'b-206',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Heinätavi',
    nameLatin: 'Anas querquedula',
    nameEn: 'Garganey',
    rarity: 4,
    photo: 'heinatavi.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'www.luontoportti.com/suomi/fi/linnut/heinatavi'
  },
  {
    id: 'b-207',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Punasotka',
    nameLatin: 'Aythya ferina',
    nameEn: 'Common Pochard',
    rarity: 4,
    photo: 'punasotka.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/punasotka'
  },
  {
    id: 'b-208',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Lapasotka',
    nameLatin: 'Aythya marila',
    nameEn: 'Greater Scaup',
    rarity: 4,
    photo: 'lapasotka.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/lapasotka'
  },
  {
    id: 'b-209',
    orderLatin: 'Podicipediformes',
    orderFi: 'Uikkulinnut',
    familyLatin: 'Podicipedidae',
    familyFi: 'Uikut',
    nameFi: 'Pikku-uikku',
    nameLatin: 'Tachybaptus ruficollis',
    nameEn: 'Little Grebe',
    rarity: 5,
    photo: 'pikku-uikku.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pikku-uikku'
  },
  {
    id: 'b-210',
    orderLatin: 'Accipitriformes',
    orderFi: 'Päiväpetolinnut',
    familyLatin: 'Accipitridae',
    familyFi: 'Haukat',
    nameFi: 'Kiljukotka',
    nameLatin: 'Aquila clanga',
    nameEn: 'Greater Spotted Eagle',
    rarity: 5,
    photo: 'kiljukotka.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://fi.wikipedia.org/wiki/Kiljukotka'
  },
  {
    id: 'b-211',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Pikkusirri',
    nameLatin: 'Calidris minuta',
    nameEn: 'Little Stint',
    rarity: 5,
    photo: 'pikkusirri.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pikkusirri'
  },
  {
    id: 'b-212',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Lapinsirri',
    nameLatin: 'Calidris temminckii',
    nameEn: "Temminck's Stint",
    rarity: 4,
    photo: 'lapinsirri.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/lapinsirri'
  },
  {
    id: 'b-213',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Punakuiri',
    nameLatin: 'Limosa lapponica',
    nameEn: 'Bar-tailed Godwit',
    rarity: 3,
    photo: 'punakuiri.jpg',
    photographer: 'Tapio Rasmus',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/punakuiri'
  },
  {
    id: 'b-214',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Mustaviklo',
    nameLatin: 'Tringa erythropus',
    nameEn: 'Spotted Redshank',
    rarity: 3,
    photo: 'mustaviklo.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/mustaviklo'
  },
  {
    id: 'b-215',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Jänkäsirriäinen',
    nameLatin: 'Calidris falcinellus',
    nameEn: 'Broad-billed Sandpiper',
    rarity: 4,
    photo: 'jankasirriainen.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/jankasirriainen'
  },
  {
    id: 'b-216',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Vesipääsky',
    nameLatin: 'Phalaropus lobatus',
    nameEn: 'Red-necked Phalarope',
    rarity: 3,
    photo: 'vesipaasky.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/vesipaasky'
  },
  {
    id: 'b-217',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Charadriidae',
    familyFi: 'Kurmitsat',
    nameFi: 'Keräkurmitsa',
    nameLatin: 'Charadrius morinellus',
    nameEn: 'Eurasian Dotterel',
    rarity: 4,
    photo: 'kerakurmitsa.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kerakurmitsa'
  },
  {
    id: 'b-218',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Heinäkurppa',
    nameLatin: 'Gallinago media',
    nameEn: 'Great Snipe',
    rarity: 5,
    photo: 'heinakurppa.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://luontoportti.com/t/1684/heinakurppa'
  },
  {
    id: 'b-219',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Stercorariidae',
    familyFi: 'Kihut',
    nameFi: 'Merikihu',
    nameLatin: 'Stercorarius parasiticus',
    nameEn: 'Parasitic Jaeger',
    rarity: 3,
    photo: 'merikihu.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/merikihu'
  },
  {
    id: 'b-220',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Sternidae',
    familyFi: 'Tiirat',
    nameFi: 'Pikkutiira',
    nameLatin: 'Sternula albifrons',
    nameEn: 'Little Tern',
    rarity: 5,
    photo: 'pikkutiira.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pikkutiira'
  },
  {
    id: 'b-221',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Sternidae',
    familyFi: 'Tiirat',
    nameFi: 'Mustatiira',
    nameLatin: 'Chlidonias niger',
    nameEn: 'Black Tern',
    rarity: 5,
    photo: 'mustatiira.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/mustatiira'
  },
  {
    id: 'b-222',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Alcidae',
    familyFi: 'Ruokit',
    nameFi: 'Riskilä',
    nameLatin: 'Cepphus grylle',
    nameEn: 'Black Guillemot',
    rarity: 4,
    photo: 'riskila.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/riskila'
  },
  {
    id: 'b-223',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Alcidae',
    familyFi: 'Ruokit',
    nameFi: 'Ruokki',
    nameLatin: 'Alca torda',
    nameEn: 'Razorbill',
    rarity: 4,
    photo: 'ruokki.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/ruokki'
  },
  {
    id: 'b-224',
    orderLatin: 'Strigiformes',
    orderFi: 'Pöllölinnut',
    familyLatin: 'Strigidae',
    familyFi: 'Pöllöt',
    nameFi: 'Tunturipöllö',
    nameLatin: 'Bubo scandiacus',
    nameEn: 'Snowy Owl',
    rarity: 5,
    photo: 'tunturipollo.jpg',
    photographer: 'Eetu Hurtig',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/tunturipollo'
  },
  {
    id: 'b-225',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Alaudidae',
    familyFi: 'Kiurut',
    nameFi: 'Kangaskiuru',
    nameLatin: 'Lullula arborea',
    nameEn: 'Woodlark',
    rarity: 4,
    photo: 'kangaskiuru.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kangaskiuru'
  },
  {
    id: 'b-226',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Alaudidae',
    familyFi: 'Kiurut',
    nameFi: 'Tunturikiuru',
    nameLatin: 'Eremophila alpestris',
    nameEn: 'Horned Lark',
    rarity: 4,
    photo: 'tunturikiuru.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/tunturikiuru'
  },
  {
    id: 'b-227',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Motacillidae',
    familyFi: 'Västäräkit',
    nameFi: 'Lapinkirvinen',
    nameLatin: 'Anthus cervinus',
    nameEn: 'Red-throated Pipit',
    rarity: 4,
    photo: 'lapinkirvinen.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/lapinkirvinen'
  },
  {
    id: 'b-228',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Motacillidae',
    familyFi: 'Västäräkit',
    nameFi: 'Luotokirvinen',
    nameLatin: 'Anthus petrosus',
    nameEn: 'Eurasian Rock Pipit',
    rarity: 4,
    photo: 'luotokirvinen.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/luotokirvinen'
  },
  {
    id: 'b-229',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Motacillidae',
    familyFi: 'Västäräkit',
    nameFi: 'Sitruunavästäräkki',
    nameLatin: 'Motacilla citreola',
    nameEn: 'Citrine Wagtail',
    rarity: 4,
    photo: 'sitruunavastarakki.jpg',
    photographer: 'Kuutti Korhonen',
    detectLink:
      'https://www.luontoportti.com/suomi/fi/linnut/sitruunavastarakki'
  },
  {
    id: 'b-230',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Motacillidae',
    familyFi: 'Västäräkit',
    nameFi: 'Virtavästäräkki',
    nameLatin: 'Motacilla cinerea',
    nameEn: 'Grey Wagtail',
    rarity: 4,
    photo: 'virtavastarakki.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/virtavastarakki'
  },
  {
    id: 'b-231',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Turdidae',
    familyFi: 'Rastaat',
    nameFi: 'Sepelrastas',
    nameLatin: 'Turdus torquatus',
    nameEn: 'Ring Ouzel',
    rarity: 5,
    photo: 'sepelrastas.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/sepelrastas'
  },
  {
    id: 'b-232',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Locustellidae',
    familyFi: 'Sirkkalinnut',
    nameFi: 'Ruokosirkkalintu',
    nameLatin: 'Locustella luscinioides',
    nameEn: "Savi's Warbler",
    rarity: 5,
    photo: 'ruokosirkkalintu.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://fi.wikipedia.org/wiki/Ruokosirkkalintu'
  },
  {
    id: 'b-233',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Hippolais',
    familyFi: 'Kultarinnat',
    nameFi: 'Pikkukultarinta',
    nameLatin: 'Iduna caligata',
    nameEn: 'Booted Warbler',
    rarity: 5,
    photo: 'pikkukultarinta.jpg',
    photographer: 'Kuutti Korhonen',
    detectLink: 'https://fi.wikipedia.org/wiki/Pikkukultarinta'
  },
  {
    id: 'b-234',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Remizidae',
    familyFi: 'Pussitiaiset',
    nameFi: 'Pussitiainen',
    nameLatin: 'Remiz pendulinus',
    nameEn: 'Eurasian Penduline Tit',
    rarity: 5,
    photo: 'pussitiainen.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://fi.wikipedia.org/wiki/Pussitiainen'
  },
  {
    id: 'b-235',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Paridae',
    familyFi: 'Tiaiset',
    nameFi: 'Valkopäätiainen',
    nameLatin: 'Cyanistes cyanus',
    nameEn: 'Azure Tit',
    rarity: 5,
    photo: 'valkopaatiainen.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://fi.wikipedia.org/wiki/Valkopäätiainen'
  },
  {
    id: 'b-236',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Paridae',
    familyFi: 'Tiaiset',
    nameFi: 'Viitatiainen',
    nameLatin: 'Poecile palustris',
    nameEn: 'Marsh Tit',
    rarity: 4,
    photo: 'viitatiainen.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/viitatiainen'
  },
  {
    id: 'b-237',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Oriolidae',
    familyFi: 'Kuhankeittäjät',
    nameFi: 'Kuhankeittäjä',
    nameLatin: 'Oriolus oriolus',
    nameEn: 'Eurasian Golden Oriole',
    rarity: 4,
    photo: 'kuhankeittaja.jpg',
    photographer: 'Liam Sanchez',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kuhankeittaja'
  },
  {
    id: 'b-238',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Fringillidae',
    familyFi: 'Peipot',
    nameFi: 'Keltahemppo',
    nameLatin: 'Serinus serinus',
    nameEn: 'European Serin',
    rarity: 5,
    photo: 'keltahemppo.jpg',
    photographer: 'Lasse Kokko',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/keltahemppo'
  },
  {
    id: 'b-239',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Fringillidae',
    familyFi: 'Peipot',
    nameFi: 'Vuorihemppo',
    nameLatin: 'Carduelis flavirostris',
    nameEn: 'Twite',
    rarity: 5,
    photo: 'vuorihemppo.jpg',
    photographer: 'Kuutti Korhonen',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/vuorihemppo'
  },
  {
    id: 'b-240',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Fringillidae',
    familyFi: 'Peipot',
    nameFi: 'Kirjosiipikäpylintu',
    nameLatin: 'Loxia leucoptera',
    nameEn: 'Two-barred Crossbill',
    rarity: 4,
    photo: 'kirjosiipikapylintu.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink:
      'https://www.luontoportti.com/suomi/fi/linnut/kirjosiipikapylintu'
  },
  {
    id: 'b-241',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Emberizidae',
    familyFi: 'Sirkut',
    nameFi: 'Pohjansirkku',
    nameLatin: 'Emberiza rustica',
    nameEn: 'Rustic Bunting',
    rarity: 4,
    photo: 'pohjansirkku.jpg',
    photographer: 'Osmo Heikkala',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pohjansirkku'
  },
  {
    id: 'b-242',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Emberizidae',
    familyFi: 'Sirkut',
    nameFi: 'Pikkusirkku',
    nameLatin: 'Emberiza pusilla',
    nameEn: 'Little Bunting',
    rarity: 5,
    photo: 'pikkusirkku.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/pikkusirkku'
  },
  {
    id: 'b-243',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Punakaulahanhi',
    nameLatin: 'Branta ruficollis',
    nameEn: 'Red-breasted Goose',
    rarity: 5,
    photo: 'punakaulahanhi.jpg',
    photographer: 'pixabay.com',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/punakaulahanhi'
  },
  {
    id: 'b-244',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Sepelhanhi',
    nameLatin: 'Branta bernicla',
    nameEn: 'Brant Goose',
    rarity: 5,
    photo: 'sepelhanhi.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/sepelhanhi'
  },
  {
    id: 'b-245',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Mandariinisorsa',
    nameLatin: 'Aix galericulata',
    nameEn: 'Mandarin Duck',
    rarity: 5,
    photo: 'mandariinisorsa.jpg',
    photographer: 'Kuutti Korhonen',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/mandariinisorsa'
  },
  {
    id: 'b-246',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Amerikantavi',
    nameLatin: 'Anas carolinensis',
    nameEn: 'Green-winged Teal',
    rarity: 5,
    photo: 'amerikantavi.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://fi.wikipedia.org/wiki/Amerikantavi'
  },
  {
    id: 'b-247',
    orderLatin: 'Accipitriformes',
    orderFi: 'Päiväpetolinnut',
    familyLatin: 'Accipitridae',
    familyFi: 'Haukat',
    nameFi: 'Munkkikorppikotka',
    nameLatin: 'Aegypius monachus',
    nameEn: 'Cinereous Vulture',
    rarity: 5,
    photo: 'munkkikorppikotka.jpg',
    photographer: 'pixabay.com',
    detectLink: 'https://fi.wikipedia.org/wiki/Munkkikorppikotka'
  },
  {
    id: 'b-248',
    orderLatin: 'Accipitriformes',
    orderFi: 'Päiväpetolinnut',
    familyLatin: 'Accipitridae',
    familyFi: 'Haukat',
    nameFi: 'Käärmekotka',
    nameLatin: 'Circaetus gallicus',
    nameEn: 'Short-toed Snake Eagle',
    rarity: 5,
    photo: 'kaarmekotka.jpg',
    photographer: 'Kuutti Korhonen',
    detectLink: 'https://fi.wikipedia.org/wiki/Käärmekotka'
  },
  {
    id: 'b-249',
    orderLatin: 'Accipitriformes',
    orderFi: 'Päiväpetolinnut',
    familyLatin: 'Accipitridae',
    familyFi: 'Haukat',
    nameFi: 'Pikkukiljukotka',
    nameLatin: 'Aquila pomarina',
    nameEn: 'Lesser Spotted Eagle',
    rarity: 5,
    photo: 'pikkukiljukotka.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://fi.wikipedia.org/wiki/Pikkukiljukotka'
  },
  {
    id: 'b-250',
    orderLatin: 'Accipitriformes',
    orderFi: 'Päiväpetolinnut',
    familyLatin: 'Accipitridae',
    familyFi: 'Haukat',
    nameFi: 'Pikkukotka',
    nameLatin: 'Aquila pennata',
    nameEn: 'Booted Eagle',
    rarity: 5,
    photo: 'pikkukotka.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://fi.wikipedia.org/wiki/Pikkukotka'
  },
  {
    id: 'b-251',
    orderLatin: 'Accipitriformes',
    orderFi: 'Päiväpetolinnut',
    familyLatin: 'Accipitridae',
    familyFi: 'Haukat',
    nameFi: 'Arohiirihaukka',
    nameLatin: 'Buteo rufinus',
    nameEn: 'Long-legged Buzzard',
    rarity: 5,
    photo: 'arohiirihaukka.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://fi.wikipedia.org/wiki/Arohiirihaukka'
  },
  {
    id: 'b-252',
    orderLatin: 'Falconiformes',
    orderFi: 'Jalohaukkalinnut',
    familyLatin: 'Falconidae',
    familyFi: 'Jalohaukat',
    nameFi: 'Välimerenhaukka',
    nameLatin: 'Falco eleonorae',
    nameEn: "Eleonora's Falcon",
    rarity: 5,
    photo: 'valimerenhaukka.jpg',
    photographer: 'Liam Sanchez',
    detectLink: 'https://fi.wikipedia.org/wiki/Välimerenhaukka'
  },
  {
    id: 'b-253',
    orderLatin: 'Falconiformes',
    orderFi: 'Jalohaukkalinnut',
    familyLatin: 'Falconidae',
    familyFi: 'Jalohaukat',
    nameFi: 'Tunturihaukka',
    nameLatin: 'Falco rusticolus',
    nameEn: 'Gyrfalcon',
    rarity: 5,
    photo: 'tunturihaukka.jpg',
    photographer: 'Hannu Majava',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/tunturihaukka'
  },
  {
    id: 'b-254',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Burhinidae',
    familyFi: 'Paksujalat',
    nameFi: 'Paksujalka',
    nameLatin: 'Burhinus oedicnemus',
    nameEn: 'Eurasian Stone-curlew',
    rarity: 5,
    photo: 'paksujalka.jpg',
    photographer: 'pixabay.com',
    detectLink: 'https://fi.wikipedia.org/wiki/Paksujalka'
  },
  {
    id: 'b-255',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Isovesipääsky',
    nameLatin: 'Phalaropus fulicarius',
    nameEn: 'Red Phalarope',
    rarity: 5,
    photo: 'isovesipaasky.jpg',
    photographer: 'Kuutti Korhonen',
    detectLink: 'https://fi.wikipedia.org/wiki/Isovesipääsky'
  },
  {
    id: 'b-256',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Rantakurvi',
    nameLatin: 'Xenus cinereus',
    nameEn: 'Terek Sandpiper',
    rarity: 5,
    photo: 'rantakurvi.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/rantakurvi'
  },
  {
    id: 'b-257',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Hirundinidae',
    familyFi: 'Pääskyt',
    nameFi: 'Ruostepääsky',
    nameLatin: 'Cecropis daurica',
    nameEn: 'Red-rumped Swallow',
    rarity: 5,
    photo: 'ruostepaasky.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://fi.wikipedia.org/wiki/Ruostepääsky'
  },
  {
    id: 'b-258',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Motacillidae',
    familyFi: 'Västäräkit',
    nameFi: 'Isokirvinen',
    nameLatin: 'Anthus richardi',
    nameEn: "Richard's Pipit",
    rarity: 5,
    photo: 'isokirvinen.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://fi.wikipedia.org/wiki/Isokirvinen'
  },
  {
    id: 'b-259',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Sylviidae',
    familyFi: 'Kertut',
    nameFi: 'Kirjokerttu',
    nameLatin: 'Sylvia nisoria',
    nameEn: 'Barred Warbler',
    rarity: 5,
    photo: 'kirjokerttu.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://fi.wikipedia.org/wiki/Kirjokerttu'
  },
  {
    id: 'b-260',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Phylloscopidae',
    familyFi: 'Uulinnut',
    nameFi: 'Idänuunilintu',
    nameLatin: 'Phylloscopus trochiloides',
    nameEn: 'Greenish Warbler',
    rarity: 5,
    photo: 'idanuunilintu.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/idanuunilintu'
  },
  {
    id: 'b-261',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Phylloscopidae',
    familyFi: 'Uulinnut',
    nameFi: 'Lapinuunilintu',
    nameLatin: 'Phylloscopus borealis',
    nameEn: 'Arctic Warbler',
    rarity: 5,
    photo: 'lapinuunilintu.jpg',
    photographer: 'Osmo Heikkala',
    detectLink: 'https://fi.wikipedia.org/wiki/Lapinuunilintu'
  },
  {
    id: 'b-262',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Phylloscopidae',
    familyFi: 'Uulinnut',
    nameFi: 'Hippiäisuunilintu',
    nameLatin: 'Phylloscopus proregulus',
    nameEn: "Pallas's Leaf Warbler",
    rarity: 5,
    photo: 'hippiaisuunilintu.jpg',
    photographer: 'Kuutti Korhonen',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/hippiaisuunilintu'
  },
  {
    id: 'b-263',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Regulidae',
    familyFi: 'Hippiäiset',
    nameFi: 'Tulipäähippiäinen',
    nameLatin: 'Regulus ignicapilla',
    nameEn: 'Common Firecrest',
    rarity: 5,
    photo: 'tulipaahippiainen.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://fi.wikipedia.org/wiki/Tulipäähippiäinen'
  },
  {
    id: 'b-264',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Laniidae',
    familyFi: 'Lepinkäiset',
    nameFi: 'Mustaotsalepinkäinen',
    nameLatin: 'Lanius minor',
    nameEn: 'Lesser Grey Shrike',
    rarity: 5,
    photo: 'mustaotsalepinkainen.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://fi.wikipedia.org/wiki/Mustaotsalepinkäinen'
  },
  {
    id: 'b-265',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Sturnidae',
    familyFi: 'Kottaraiset',
    nameFi: 'Punakottarainen',
    nameLatin: 'Pastor roseus',
    nameEn: 'Rosy Starling',
    rarity: 5,
    photo: 'punakottarainen.jpg',
    photographer: 'Kuutti Korhonen',
    detectLink: 'https://fi.wikipedia.org/wiki/Punakottarainen'
  },
  {
    id: 'b-266',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Acrocephalidae',
    familyFi: 'Kerttuset',
    nameFi: 'Rastaskerttunen',
    nameLatin: 'Acrocephalus arundinaceus',
    nameEn: 'Great Reed Warbler',
    rarity: 5,
    photo: 'rastaskerttunen.jpg',
    photographer: 'Liam Sanchez',
    detectLink: 'https://fi.wikipedia.org/wiki/Rastaskerttunen'
  },
  {
    id: 'b-267',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Locustellidae',
    familyFi: 'Sirkkalinnut',
    nameFi: 'Viitasirkkalintu',
    nameLatin: 'Locustella fluviatilis',
    nameEn: 'River Warbler',
    rarity: 3,
    photo: 'viitasirkkalintu.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://fi.wikipedia.org/wiki/Viitasirkkalintu'
  },
  {
    id: 'b-268',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Lyhytnokkahanhi',
    nameLatin: 'Anser brachyrhynchus',
    nameEn: 'Pink-footed Goose',
    rarity: 5,
    photo: 'lyhytnokkahanhi.jpg',
    photographer: 'Jyri Lyytikäinen',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/lyhytnokkahanhi'
  },
  {
    id: 'b-269',
    orderLatin: 'Ciconiiformes',
    orderFi: 'Haikaralinnut',
    familyLatin: 'Ciconiidae',
    familyFi: 'Kattohaikarat',
    nameFi: 'Kattohaikara',
    nameLatin: 'Ciconia ciconia',
    nameEn: 'White Stork',
    rarity: 5,
    photo: 'kattohaikara.jpg',
    photographer: 'Henrikki Hetemaa',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kattohaikara'
  },
  {
    id: 'b-270',
    orderLatin: 'Podicipediformes',
    orderFi: 'Uikkulinnut',
    familyLatin: 'Podicipedidae',
    familyFi: 'Uikut',
    nameFi: 'Mustakaulauikku',
    nameLatin: 'Podiceps nigricollis',
    nameEn: 'Black-necked Grebe',
    rarity: 5,
    photo: 'mustakaulauikku.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://fi.wikipedia.org/wiki/Mustakaulauikku'
  },
  {
    id: 'b-271',
    orderLatin: 'Gaviiformes',
    orderFi: 'Kuikkalinnut',
    familyLatin: 'Gaviiformes',
    familyFi: 'Kuikat',
    nameFi: 'Jääkuikka',
    nameLatin: 'Gavia adamsii',
    nameEn: 'Yellow-billed Loon',
    rarity: 5,
    photo: 'jaakuikka.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://fi.wikipedia.org/wiki/Jääkuikka'
  },
  {
    id: 'b-272',
    orderLatin: 'Gaviiformes',
    orderFi: 'Kuikkalinnut',
    familyLatin: 'Gaviiformes',
    familyFi: 'Kuikat',
    nameFi: 'Amerikanjääkuikka',
    nameLatin: 'Gavia immer',
    nameEn: 'Great Northern Loon',
    rarity: 5,
    photo: 'amerikanjaakuikka.jpg',
    photographer: 'Mia Surakka',
    detectLink: 'https://fi.wikipedia.org/wiki/Amerikanjääkuikka'
  },
  {
    id: 'b-273',
    orderLatin: 'Gaviiformes',
    orderFi: 'Kuikkalinnut',
    familyLatin: 'Gaviiformes',
    familyFi: 'Kuikat',
    nameFi: 'Tundrakuikka',
    nameLatin: 'Gavia pacifica',
    nameEn: 'Pacific Loon',
    rarity: 5,
    photo: 'tundrakuikka.jpg',
    photographer: 'Eino Hiekkanen',
    detectLink: 'https://fi.wikipedia.org/wiki/Tundrakuikka'
  },
  {
    id: 'b-274',
    orderLatin: 'Strigiformes',
    orderFi: 'Pöllölinnut',
    familyLatin: 'Tytonidae',
    familyFi: 'Tornipöllöt',
    nameFi: 'Tornipöllö',
    nameLatin: 'Tyto alba',
    nameEn: 'Barn Owl',
    rarity: 5,
    photo: 'tornipollo.jpg',
    photographer: 'pixabay.com',
    detectLink: 'https://fi.wikipedia.org/wiki/Tornipöllö'
  },
  {
    id: 'b-275',
    orderLatin: 'Strigiformes',
    orderFi: 'Pöllölinnut',
    familyLatin: 'Strigidae',
    familyFi: 'Pöllöt',
    nameFi: 'Minervanpöllö',
    nameLatin: 'Athene noctua',
    nameEn: 'Little Owl',
    rarity: 5,
    photo: 'minervanpollo.jpg',
    photographer: 'pixabay.com',
    detectLink: 'https://fi.wikipedia.org/wiki/Minervanpöllö'
  },
  {
    id: 'b-276',
    orderLatin: 'Strigiformes',
    orderFi: 'Pöllölinnut',
    familyLatin: 'Strigidae',
    familyFi: 'Pöllöt',
    nameFi: 'Kyläpöllönen',
    nameLatin: 'Otus scops',
    nameEn: 'Eurasian Scops Owl',
    rarity: 5,
    photo: 'kylapollonen.jpg',
    photographer: 'Eino Hiekkanen',
    detectLink: 'https://fi.wikipedia.org/wiki/Kyläpöllönen'
  },
  {
    id: 'b-277',
    orderLatin: 'Coraciiformes',
    orderFi: 'Säihkylinnut',
    familyLatin: 'Meropidae',
    familyFi: 'Mehiläissyöjät',
    nameFi: 'Mehiläissyöjä',
    nameLatin: 'Merops apiaster',
    nameEn: 'European Bee-eater',
    rarity: 5,
    photo: 'mehilaissyoja.jpg',
    photographer: 'Liam Sanchez',
    detectLink: 'https://fi.wikipedia.org/wiki/Mehiläissyöjä'
  },
  {
    id: 'b-278',
    orderLatin: 'Piciformes',
    orderFi: 'Tikkalinnut',
    familyLatin: 'Picidae',
    familyFi: 'Tikat',
    nameFi: 'Tammitikka',
    nameLatin: 'Dendrocopos medius',
    nameEn: 'Middle Spotted Woodpecker',
    rarity: 5,
    photo: 'tammitikka.jpg',
    photographer: 'Liam Sanchez',
    detectLink: 'https://fi.wikipedia.org/wiki/Tammitikka'
  },
  {
    id: 'b-279',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Ruostesorsa',
    nameLatin: 'Tadorna ferruginea',
    nameEn: 'Ruddy Shelduck',
    rarity: 5,
    photo: 'ruostesorsa.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://fi.wikipedia.org/wiki/Ruostesorsa'
  },
  {
    id: 'b-280',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Amerikanhaapana',
    nameLatin: 'Anas americana',
    nameEn: 'American Wigeon',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.wikipedia.org/wiki/Amerikanhaapana'
  },
  {
    id: 'b-281',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Sinisiipitavi',
    nameLatin: 'Anas discors',
    nameEn: 'Blue-winged Teal',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.wikipedia.org/wiki/Sinisiipitavi'
  },
  {
    id: 'b-282',
    orderLatin: 'Procellariiformes',
    orderFi: 'Ulappalinnut',
    familyLatin: 'Procellariidae',
    familyFi: 'Ulappaliitäjät',
    nameFi: 'Myrskylintu',
    nameLatin: 'Fulmarus glacialis',
    nameEn: 'Northern Fulmar',
    rarity: 5,
    photo: 'myrskylintu.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://fi.wikipedia.org/wiki/Myrskylintu'
  },
  {
    id: 'b-283',
    orderLatin: 'Accipitriformes',
    orderFi: 'Päiväpetolinnut',
    familyLatin: 'Accipitridae',
    familyFi: 'Haukat',
    nameFi: 'Isohaarahaukka',
    nameLatin: 'Milvus milvus',
    nameEn: 'Red Kite',
    rarity: 5,
    photo: 'isohaarahaukka.jpg',
    photographer: 'Liam Sanchez',
    detectLink: 'https://fi.wikipedia.org/wiki/Isohaarahaukka'
  },
  {
    id: 'b-284',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Kuparisorsa',
    nameLatin: 'Oxyura jamaicensis',
    nameEn: 'Ruddy Duck',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.wikipedia.org/wiki/Kuparisorsa'
  },
  {
    id: 'b-285',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Kyhmypilkkasiipi',
    nameLatin: 'Melanitta deglandi',
    nameEn: 'White-winged Scoter',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.wikipedia.org/wiki/Kyhmypilkkasiipi'
  },
  {
    id: 'b-286',
    orderLatin: 'Coraciiformes',
    orderFi: 'Säihkylinnut',
    familyLatin: 'Coraciidae',
    familyFi: 'Säihkynärhet',
    nameFi: 'Sininärhi',
    nameLatin: 'Coracias garrulus',
    nameEn: 'European Roller',
    rarity: 5,
    photo: 'sininarhi.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://fi.wikipedia.org/wiki/Sininärhi'
  },
  {
    id: 'b-287',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Turdidae',
    familyFi: 'Rastaat',
    nameFi: 'Ruosterastas',
    nameLatin: 'Turdus naumanni',
    nameEn: "Naumann's Thrush",
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.wikipedia.org/wiki/Ruosterastas'
  },
  {
    id: 'b-288',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Turdidae',
    familyFi: 'Rastaat',
    nameFi: 'Ruostesiipirastas',
    nameLatin: 'Turdus eunomus',
    nameEn: 'Dusky Thrush',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.wikipedia.org/wiki/Ruostesiipirastas'
  },
  {
    id: 'b-289',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Turdidae',
    familyFi: 'Rastaat',
    nameFi: 'Mustakaularastas',
    nameLatin: 'Turdus atrogularis',
    nameEn: 'Black-throated Thrush',
    rarity: 5,
    photo: 'mustakaularastas.jpg',
    photographer: 'Henrikki Hetemaa',
    detectLink: 'https://fi.wikipedia.org/wiki/Mustakaularastas'
  },
  {
    id: 'b-290',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Turdidae',
    familyFi: 'Rastaat',
    nameFi: 'Kivikkorastas',
    nameLatin: 'Monticola saxatilis',
    nameEn: 'Common Rock Thrush',
    rarity: 5,
    photo: 'kivikkorastas.jpg',
    photographer: 'Eino Hiekkanen',
    detectLink: 'https://fi.wikipedia.org/wiki/Kivikkorastas'
  },
  {
    id: 'b-291',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Turdidae',
    familyFi: 'Rastaat',
    nameFi: 'Sinirastas',
    nameLatin: 'Monticola solitarius',
    nameEn: 'Blue Rock Thrush',
    rarity: 5,
    photo: 'sinirastas.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://fi.wikipedia.org/wiki/Sinirastas'
  },
  {
    id: 'b-292',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Muscicapidae',
    familyFi: 'Siepot',
    nameFi: 'Nunnatasku',
    nameLatin: 'Oenanthe pleschanka',
    nameEn: 'Pied Wheatear',
    rarity: 5,
    photo: 'nunnatasku.jpg',
    photographer: 'Liam Sanchez',
    detectLink: 'https://fi.wikipedia.org/wiki/Nunnatasku'
  },
  {
    id: 'b-293',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Muscicapidae',
    familyFi: 'Siepot',
    nameFi: 'Rusotasku',
    nameLatin: 'Oenanthe hispanica',
    nameEn: 'Black-eared Wheatear',
    rarity: 5,
    photo: 'rusotasku.jpg',
    photographer: 'Arvi Tyni',
    detectLink: 'https://fi.wikipedia.org/wiki/Rusotasku'
  },
  {
    id: 'b-294',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Muscicapidae',
    familyFi: 'Siepot',
    nameFi: 'Mustapäätasku',
    nameLatin: 'Saxicola rubicola',
    nameEn: 'European Stonechat',
    rarity: 5,
    photo: 'mustapaatasku.jpg',
    photographer: 'Terho Kaikkonen',
    detectLink: 'https://fi.wikipedia.org/wiki/Mustapäätasku'
  },
  {
    id: 'b-295',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Muscicapidae',
    familyFi: 'Siepot',
    nameFi: 'Nokitasku',
    nameLatin: 'Saxicola caprata',
    nameEn: 'Pied Bush Chat',
    rarity: 5,
    photo: 'nokitasku.jpg',
    photographer: 'Kalle Hiekkanen',
    detectLink: 'https://fi.wikipedia.org/wiki/Nokitasku'
  },
  {
    id: 'b-296',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Charadriidae',
    familyFi: 'Kurmitsat',
    nameFi: 'Siperiankurmitsa',
    nameLatin: 'Pluvialis fulva',
    nameEn: 'Pacific Golden Plover',
    rarity: 5,
    photo: 'siperiankurmitsa.jpg',
    photographer: 'Eino Hiekkanen',
    detectLink: 'https://fi.wikipedia.org/wiki/Siperiankurmitsa'
  },
  {
    id: 'b-297',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Charadriidae',
    familyFi: 'Kurmitsat',
    nameFi: 'Amerikankurmitsa',
    nameLatin: 'Pluvialis dominica',
    nameEn: 'American Golden Plover',
    rarity: 5,
    photo: 'amerikankurpitsa.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://fi.wikipedia.org/wiki/Amerikankurmitsa'
  },
  {
    id: 'b-298',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Isosirri',
    nameLatin: 'Calidris canutus',
    nameEn: 'Red Knot',
    rarity: 5,
    photo: 'isosirri.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://fi.wikipedia.org/wiki/Isosirri'
  },
  {
    id: 'b-299',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Laridae',
    familyFi: 'Lokit',
    nameFi: 'Ruusulokki',
    nameLatin: 'Rhodostethia rosea',
    nameEn: "Ross's Gull",
    rarity: 5,
    photo: 'ruusulokki.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://fi.wikipedia.org/wiki/Ruusulokki'
  },
  {
    id: 'b-300',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Laridae',
    familyFi: 'Lokit',
    nameFi: 'Jäälokki',
    nameLatin: 'Pagophila eburnea',
    nameEn: 'Ivory Gull',
    rarity: 5,
    photo: 'jaalokki.jpg',
    photographer: 'Eino Hiekkanen',
    detectLink: 'https://fi.wikipedia.org/wiki/Jäälokki'
  },
  {
    id: 'b-301',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Laridae',
    familyFi: 'Lokit',
    nameFi: 'Tiiralokki',
    nameLatin: 'Xema sabini',
    nameEn: "Sabine's Gull",
    rarity: 5,
    photo: 'tiiralokki.jpg',
    photographer: 'Eino Hiekkanen',
    detectLink: 'https://fi.wikipedia.org/wiki/Tiiralokki'
  },
  {
    id: 'b-302',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Fringillidae',
    familyFi: 'Peipot',
    nameFi: 'Taviokuurna',
    nameLatin: 'Pinicola enucleator',
    nameEn: 'Pine Grosbeak',
    rarity: 3,
    photo: 'taviokuurna.jpg',
    photographer: 'Henrikki Hetemaa',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/taviokuurna'
  },
  {
    id: 'b-303',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Kuovisirri',
    nameLatin: 'Calidris ferruginea',
    nameEn: 'Curlew Sandpiper',
    rarity: 5,
    photo: 'kuovisirri.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kuovisirri'
  },
  {
    id: 'b-304',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Laridae',
    familyFi: 'Lokit',
    nameFi: 'Aroharmaalokki',
    nameLatin: 'Larus cachinnans',
    nameEn: 'Caspian Gull',
    rarity: 5,
    photo: 'aroharmaalokki.jpg',
    photographer: 'Kari Isokivijärvi',
    detectLink: 'https://fi.wikipedia.org/wiki/Aroharmaalokki'
  },
  {
    id: 'b-305',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Charadriidae',
    familyFi: 'Kurmitsat',
    nameFi: 'Tundrakurmitsa',
    nameLatin: 'Pluvialis squatarola',
    nameEn: 'Grey Plover',
    rarity: 5,
    photo: 'tundrakurmitsa.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/tundrakurmitsa'
  },
  {
    id: 'b-306',
    orderLatin: 'Columbiformes',
    orderFi: 'Kyyhkylinnut',
    familyLatin: 'Columbidae',
    familyFi: 'Kyyhkyt',
    nameFi: 'Turturikyyhky',
    nameLatin: 'Streptopelia turtur',
    nameEn: 'European Turtle Dove',
    rarity: 5,
    photo: 'turturikyyhky.jpg',
    photographer: 'Terho Kaikkonen',
    detectLink: 'https://fi.wikipedia.org/wiki/Turturikyyhky'
  },
  {
    id: 'b-307',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Locustellidae',
    familyFi: 'Sirkkalinnut',
    nameFi: 'Viirusirkkalintu',
    nameLatin: 'Locustella lanceolata',
    nameEn: 'Lanceolated Warbler',
    rarity: 5,
    photo: 'viirusirkkalintu.jpg',
    photographer: 'Osmo Heikkala',
    detectLink: 'https://fi.wikipedia.org/wiki/Viirusirkkalintu'
  },
  {
    id: 'b-308',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Lampiviklo',
    nameLatin: 'Tringa stagnatilis',
    nameEn: 'Marsh Sandpiper',
    rarity: 5,
    photo: 'lampiviklo.jpg',
    photographer: 'Kalle Hiekkanen',
    detectLink: 'https://fi.wikipedia.org/wiki/Lampiviklo'
  },
  {
    id: 'b-309',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Pilkkaniska',
    nameLatin: 'Melanitta perspicillata',
    nameEn: 'Surf Scoter',
    rarity: 5,
    photo: 'pilkkaniska.jpg',
    photographer: 'Eino Hiekkanen',
    detectLink: 'https://fi.wikipedia.org/wiki/Pilkkaniska'
  },
  {
    id: 'b-310',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Alcidae',
    familyFi: 'Ruokit',
    nameFi: 'Etelänkiisla',
    nameLatin: 'Uria aalge',
    nameEn: 'Common Murre',
    rarity: 5,
    photo: 'etelankiisla.jpg',
    photographer: 'Terho Kaikkonen',
    detectLink: 'https://fi.wikipedia.org/wiki/Etel%C3%A4nkiisla'
  },
  {
    id: 'b-311',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Merisirri',
    nameLatin: 'Calidris maritima',
    nameEn: 'Purple Sandpiper',
    rarity: 5,
    photo: 'merisirri.jpg',
    photographer: 'Juho Tirkkonen',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/merisirri'
  },
  {
    id: 'b-312',
    orderLatin: 'Ciconiiformes',
    orderFi: 'Haikaralinnut',
    familyLatin: 'Ardeidae',
    familyFi: 'Haikarat',
    nameFi: 'Lehmähaikara',
    nameLatin: 'Bubulcus ibis',
    nameEn: 'Cattle Egret',
    rarity: 5,
    photo: 'lehmahaikara.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://fi.wikipedia.org/wiki/Lehm%C3%A4haikara'
  },
  {
    id: 'b-313',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Emberizidae',
    familyFi: 'Sirkut',
    nameFi: 'Mäntysirkku',
    nameLatin: 'Emberiza leucocephalos',
    nameEn: 'Pine Bunting',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.wikipedia.org/wiki/M%C3%A4ntysirkku'
  },
  {
    id: 'b-314',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Eskimosirri',
    nameLatin: 'Calidris bairdii',
    nameEn: "Baird's Sandpiper",
    rarity: 5,
    photo: 'eskimosirri.jpg',
    photographer: 'Kalle Hiekkanen',
    detectLink: 'https://fi.wikipedia.org/wiki/Eskimosirri'
  },
  {
    id: 'b-315',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Punapäänarsku',
    nameLatin: 'Netta rufina',
    nameEn: 'Red-crested Pochard',
    rarity: 5,
    photo: 'punapaanarsku.jpg',
    photographer: 'Eino Hiekkanen',
    detectLink: 'https://fi.wikipedia.org/wiki/Punap%C3%A4%C3%A4narsku'
  },
  {
    id: 'b-316',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Tiibetinhanhi',
    nameLatin: 'Anser indicus',
    nameEn: 'Bar-headed Goose',
    rarity: 5,
    photo: 'tiibetinhanhi.jpg',
    photographer: 'Eino Hiekkanen',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/tiibetinhanhi'
  },
  {
    id: 'b-317',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Kyhmyhaahka',
    nameLatin: 'Somateria spectabilis',
    nameEn: 'King Eider',
    rarity: 4,
    photo: 'kyhmyhaahka.jpg',
    photographer: 'Mette Skyttä',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/kyhmyhaahka'
  },
  {
    id: 'b-318',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Muscicapidae',
    familyFi: 'Siepot',
    nameFi: 'Arotasku',
    nameLatin: 'Oenanthe isabellina',
    nameEn: 'Isabelline Wheatear',
    rarity: 5,
    photo: 'arotasku.jpg',
    photographer: 'Juho Tirkkonen',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Arotasku'
  },
  {
    id: 'b-319',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Allihaahka',
    nameLatin: 'Polysticta stelleri',
    nameEn: "Steller's Eider",
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/allihaahka'
  },
  {
    id: 'b-320',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Muscicapidae',
    familyFi: 'Siepot',
    nameFi: 'Mustaleppälintu',
    nameLatin: 'Phoenicurus ochruros',
    nameEn: 'Black Redstart',
    rarity: 5,
    photo: 'mustaleppalintu.jpg',
    photographer: 'Terho Kaikkonen',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Mustalepp%C3%A4lintu'
  },
  {
    id: 'b-321',
    orderLatin: 'Ciconiiformes',
    orderFi: 'Haikaralinnut',
    familyLatin: 'Ardeidae',
    familyFi: 'Haikarat',
    nameFi: 'Mustahaikara',
    nameLatin: 'Ciconia nigra',
    nameEn: 'Black Stork',
    rarity: 5,
    photo: 'mustahaikara.jpg',
    photographer: 'Terho Kaikkonen',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/mustahaikara'
  },
  {
    id: 'b-322',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Muscicapidae',
    familyFi: 'Siepot',
    nameFi: 'Sepeltasku',
    nameLatin: 'Saxicola maurus',
    nameEn: 'Siberian Stonechat',
    rarity: 5,
    photo: 'sepeltasku.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Sepeltasku'
  },
  {
    id: 'b-323',
    orderLatin: 'Galliformes',
    orderFi: 'Kanalinnut',
    familyLatin: 'Phasianidae',
    familyFi: 'Aitokanat',
    nameFi: 'Viiriäinen',
    nameLatin: 'Coturnix coturnix',
    nameEn: 'Common Quail',
    rarity: 4,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/viiriainen'
  },
  {
    id: 'b-324',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Glareolidae',
    familyFi: 'Pääskykahlaajat',
    nameFi: 'Pääskykahlaaja',
    nameLatin: 'Glareola pratincola',
    nameEn: 'Collared Pratincole',
    rarity: 5,
    photo: 'paaskykahlaaja.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://fi.m.wikipedia.org/wiki/P%C3%A4%C3%A4skykahlaaja'
  },
  {
    id: 'b-325',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Sternidae',
    familyFi: 'Tiirat',
    nameFi: 'Riuttatiira',
    nameLatin: 'Sterna sandvicensis',
    nameEn: 'Sandwich Tern',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Riuttatiira'
  },
  {
    id: 'b-326',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Emberizidae',
    familyFi: 'Sirkut',
    nameFi: 'Mustapääsirkku',
    nameLatin: 'Emberiza melanocephala',
    nameEn: 'Corn Bunting',
    rarity: 5,
    photo: 'mustapaasirkku.jpg',
    photographer: 'Terho Kaikkonen',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Mustap%C3%A4%C3%A4sirkku'
  },
  {
    id: 'b-327',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Laridae',
    familyFi: 'Lokit',
    nameFi: 'Isolokki',
    nameLatin: 'Larus hyperboreus',
    nameEn: 'Glaucous Gull',
    rarity: 5,
    photo: 'isolokki.jpg',
    photographer: 'Terho Kaikkonen',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/isolokki'
  },
  {
    id: 'b-328',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Burhinidae',
    familyFi: 'Paksujalat',
    nameFi: 'Avosetti',
    nameLatin: 'Recurvirostra avosetta',
    nameEn: 'Pied Avocet',
    rarity: 5,
    photo: 'avosetti.jpg',
    photographer: 'Terho Kaikkonen',
    detectLink: 'https://www.luontoportti.com/suomi/fi/linnut/avosetti'
  },
  {
    id: 'b-329',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Muscicapidae',
    familyFi: 'Siepot',
    nameFi: 'Etelänsatakieli',
    nameLatin: 'Luscinia megarhynchos',
    nameEn: 'Common Nightingale',
    rarity: 5,
    photo: 'etelansatakieli.jpg',
    photographer: 'Terho Kaikkonen',
    detectLink: 'https://fi.wikipedia.org/wiki/Etel%C3%A4nsatakieli'
  },
  {
    id: 'b-330',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Tundrakurppelo',
    nameLatin: 'Limnodromus scolopaceus',
    nameEn: 'Long-billed Dowitcher',
    rarity: 5,
    photo: 'tundrakurppelo.jpg',
    photographer: 'Juho Tirkkonen',
    detectLink: 'https://fi.wikipedia.org/wiki/Tundrakurppelo'
  },
  {
    id: 'b-331',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Laridae',
    familyFi: 'Lokit',
    nameFi: 'Pikkukajava',
    nameLatin: 'Rissa tridactyla',
    nameEn: 'Black-legged Kittiwake',
    rarity: 5,
    photo: 'pikkukajava.jpg',
    photographer: 'Timo Saari',
    detectLink: 'https://fi.wikipedia.org/wiki/Pikkukajava'
  },
  {
    id: 'b-332',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Stercorariidae',
    familyFi: 'Kihut',
    nameFi: 'Leveäpyrstökihu',
    nameLatin: 'Stercorarius pomarinus',
    nameEn: 'Pomarine Skua',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.wikipedia.org/wiki/Leve%C3%A4pyrst%C3%B6kihu'
  },
  {
    id: 'b-333',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Motacillidae',
    familyFi: 'Västäräkit',
    nameFi: 'Taigakirvinen',
    nameLatin: 'Anthus hodgsoni',
    nameEn: 'Olive-backed Pipit',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.wikipedia.org/wiki/Taigakirvinen'
  },
  {
    id: 'b-334',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Sylviidae',
    familyFi: 'Kertut',
    nameFi: 'Ruskouunilintu',
    nameLatin: 'Phylloscopus fuscatus',
    nameEn: 'Dusky Warbler',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.wikipedia.org/wiki/Ruskouunilintu'
  },
  {
    id: 'b-335',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Phylloscopidae',
    familyFi: 'Uunilinnut',
    nameFi: 'Kashmirinuunilintu',
    nameLatin: 'Abrornis humei',
    nameEn: '',
    rarity: 5,
    photo: 'kashmirinuunilintu.jpg',
    photographer: 'Juho Tirkkonen',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Kashmirinuunilintu'
  },
  {
    id: 'b-336',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Alaudidae',
    familyFi: 'Kiurut',
    nameFi: 'Töyhtökiuru',
    nameLatin: 'Galerida cristata',
    nameEn: 'Crested Lark',
    rarity: 5,
    photo: 'toyhtokiuru.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://fi.m.wikipedia.org/wiki/T%C3%B6yht%C3%B6kiurut'
  },
  {
    id: 'b-337',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Laridae',
    familyFi: 'Lokit',
    nameFi: 'Ohotanlokki',
    nameLatin: 'Larus schistisagus',
    nameEn: '',
    rarity: 5,
    photo: 'ohotanlokki.jpg',
    photographer: 'Petri Päivärinta',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Ohotanlokki'
  },
  {
    id: 'b-338',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Muscicapidae',
    familyFi: 'Siepot',
    nameFi: 'Sepelsieppo',
    nameLatin: 'Ficedula albicollis',
    nameEn: 'Collared Flycatcher',
    rarity: 5,
    photo: 'sepelsieppo.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://fi.wikipedia.org/wiki/Sepelsieppo'
  },
  {
    id: 'b-339',
    orderLatin: 'Gruiformes',
    orderFi: 'Kurkilinnut',
    familyLatin: 'Rallidae',
    familyFi: 'Rantakanat',
    nameFi: 'Pikkuhuitti',
    nameLatin: 'Zapornia parva',
    nameEn: '',
    rarity: 5,
    photo: 'pikkuhuitti.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Pikkuhuitti'
  },
  {
    id: 'b-340',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Sternidae',
    familyFi: 'Tiirat',
    nameFi: 'Valkosiipitiira',
    nameLatin: 'Chlidonias leucopterus',
    nameEn: 'White-winged tern',
    rarity: 5,
    photo: 'valkosiipitiira.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://fi.wikipedia.org/wiki/Valkosiipitiira'
  },
  {
    id: 'b-341',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Tundravikla',
    nameLatin: 'Calidris subruficollis',
    nameEn: '',
    rarity: 5,
    photo: 'tundravikla.jpg',
    photographer: 'Terho Kaikkonen',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Tundravikla'
  },
  {
    id: 'b-342',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Palsasirri',
    nameLatin: 'Calidris melanotos',
    nameEn: '',
    rarity: 5,
    photo: 'palsasirri.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://fi.wikipedia.org/wiki/Palsasirri'
  },
  {
    id: 'b-343',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Lumihanhi',
    nameLatin: 'Anser caerulescens',
    nameEn: '',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Lumihanhi'
  },
  {
    id: 'b-344',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Stercorariidae',
    familyFi: 'Kihut',
    nameFi: 'Isokihu',
    nameLatin: 'Stercorarius skua',
    nameEn: '',
    rarity: 5,
    photo: 'isokihu.jpg',
    photographer: 'Mette Skyttä',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Isokihu'
  },
  {
    id: 'b-345',
    orderLatin: 'Gruiformes',
    orderFi: 'Kurkilinnut',
    familyLatin: 'Gruidae',
    familyFi: 'Kurjet',
    nameFi: 'Hietakurki',
    nameLatin: 'Grus canadensis',
    nameEn: '',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Hietakurki'
  },
  {
    id: 'b-346',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Laniidae',
    familyFi: 'Lepinkäiset',
    nameFi: 'Siperianlepinkäinen',
    nameLatin: 'Lanius cristatus',
    nameEn: '',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Siperianlepink%C3%A4inen'
  },
  {
    id: 'b-347',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Laridae',
    familyFi: 'Lokit',
    nameFi: 'Mustanmerenlokki',
    nameLatin: 'Ichthyaetus melanocephalus',
    nameEn: '',
    rarity: 5,
    photo: 'mustanmerenlokki.jpg',
    photographer: 'Pekka Nykänen',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Mustanmerenlokki'
  },
  {
    id: 'b-348',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Alaudidae',
    familyFi: 'Kiurut',
    nameFi: 'Lyhytvarvaskiuru',
    nameLatin: 'Calandrella brachydactyla',
    nameEn: '',
    rarity: 5,
    photo: 'lyhytvarvaskiuru.jpg',
    photographer: 'Heikki Vuonokari',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Lyhytvarvaskiuru'
  },
  {
    id: 'b-349',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Muscicapidae',
    familyFi: 'Siepot',
    nameFi: 'Aavikkotasku',
    nameLatin: 'Oenanthe deserti',
    nameEn: '',
    rarity: 5,
    photo: 'aavikkotasku.jpg',
    photographer: 'Anna-Maija Toppinen',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Aavikkotasku'
  },
  {
    id: 'b-350',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Pikkukanadanhanhi',
    nameLatin: 'Branta hutchinsii',
    nameEn: '',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Pikkukanadanhanhi'
  },
  {
    id: 'b-351',
    orderLatin: 'Pelecaniformes',
    orderFi: 'Pelikaanilinnut',
    familyLatin: 'Phalacrocoracidae',
    familyFi: 'Merimetsot',
    nameFi: 'Pikkumerimetso',
    nameLatin: 'Microcarbo pygmeus',
    nameEn: '',
    rarity: 5,
    photo: 'pikkumerimetso.jpg',
    photographer: 'Pekka Nykänen',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Pikkumerimetso'
  },
  {
    id: 'b-352',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Ruskosotka',
    nameLatin: 'Aythya nyroca',
    nameEn: '',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Ruskosotka'
  },
  {
    id: 'b-353',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Amerikantukkasotka',
    nameLatin: 'Aythya collaris',
    nameEn: '',
    rarity: 5,
    photo: 'amerikantukkasotka.jpg',
    photographer: 'Mette Skyttä',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Amerikantukkasotka'
  },
  {
    id: 'b-354',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Keltajalkaviklo',
    nameLatin: 'Tringa flavipes',
    nameEn: '',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Keltajalkaviklo'
  },
  {
    id: 'b-355',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Charadriidae',
    familyFi: 'Kurmitsat',
    nameFi: 'Arohyyppä',
    nameLatin: 'Vanellus gregarius',
    nameEn: '',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Arohyypp%C3%A4'
  },
  {
    id: 'b-356',
    orderLatin: 'Coraciiformes',
    orderFi: 'Säihkylinnut',
    familyLatin: '',
    familyFi: 'Mehiläissyöjät',
    nameFi: 'Vihermehiläissyöjä',
    nameLatin: 'Merops persicus',
    nameEn: '',
    rarity: 5,
    photo: 'vihermehilaissyoja.jpg',
    photographer: 'Veikka Meski',
    detectLink:
      'https://fi.m.wikipedia.org/wiki/Vihermehil%C3%A4issy%C3%B6j%C3%A4'
  },
  {
    id: 'b-357',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Scolopacidae',
    familyFi: 'Kurpat',
    nameFi: 'Valkoperäsirri',
    nameLatin: '',
    nameEn: '',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.wikipedia.org/wiki/Valkoper%C3%A4sirri'
  },
  {
    id: 'b-358',
    orderLatin: 'Procellariiformes',
    orderFi: 'Ulappalinnut',
    familyLatin: 'Procellariidae',
    familyFi: 'Ulappaliitäjät',
    nameFi: 'Isoliitäjä',
    nameLatin: '',
    nameEn: '',
    rarity: 5,
    photo: 'isoliitaja.jpg',
    photographer: 'Arto Oksanen',
    detectLink: 'https://fi.wikipedia.org/wiki/Isoliit%C3%A4j%C3%A4'
  },
  {
    id: 'b-359',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Acrocephalidae',
    familyFi: 'Kerttuset',
    nameFi: 'Kenttäkerttunen',
    nameLatin: '',
    nameEn: '',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.wikipedia.org/wiki/Kentt%C3%A4kerttunen'
  },
  {
    id: 'b-360',
    orderLatin: '',
    orderFi: '',
    familyLatin: '',
    familyFi: '',
    nameFi: 'Suula',
    nameLatin: 'Morus bassanus',
    nameEn: '',
    rarity: 5,
    photo: 'suula.jpg',
    photographer: 'Mette Skyttä',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Suula'
  },
  {
    id: 'b-361',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Charadriidae',
    familyFi: 'Kurmitsat',
    nameFi: 'Mustajalkatylli',
    nameLatin: 'Charadrius alexandrinus',
    nameEn: '',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.wikipedia.org/wiki/Mustajalkatylli'
  },
  {
    id: 'b-362',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Nokisorsa',
    nameLatin: 'Anas rubripes',
    nameEn: '',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.wikipedia.org/wiki/Nokisorsa'
  },
  {
    id: 'b-363',
    orderLatin: 'Anseriformes',
    orderFi: 'Sorsalinnut',
    familyLatin: 'Anatidae',
    familyFi: 'Sorsat',
    nameFi: 'Eskimohanhi',
    nameLatin: 'Anser rossii',
    nameEn: '',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.wikipedia.org/wiki/Eskimohanhi'
  },
  {
    id: 'b-364',
    orderLatin: 'Passeriformes',
    orderFi: 'Varpuslinnut',
    familyLatin: 'Emberizidae',
    familyFi: 'Sirkut',
    nameFi: 'Pikkupajusirkku',
    nameLatin: 'Schoeniclus pallasi',
    nameEn: '',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Pikkupajusirkku'
  },
  {
    id: 'b-365',
    orderLatin: 'Charadriiformes',
    orderFi: 'Rantalinnut',
    familyLatin: 'Alcidae',
    familyFi: 'Ruokit',
    nameFi: 'Lunni',
    nameLatin: 'Fratercula arctica',
    nameEn: '',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Lunni'
  },
  {
    id: 'b-366',
    orderLatin: 'Pelecaniformes',
    orderFi: 'Pelikaanilinnut',
    familyLatin: 'Phalacrocoracidae',
    familyFi: 'Merimetsot',
    nameFi: 'Karimetso',
    nameLatin: 'Phalacrocorax aristotelis',
    nameEn: '',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.m.wikipedia.org/wiki/Karimetso'
  },
  {
    id: 'b-367',
    orderLatin: 'Ciconiiformes',
    orderFi: 'Haikaralinnut',
    familyLatin: 'Ardeidae',
    familyFi: 'Haikarat',
    nameFi: 'Rääkkähaikara',
    nameLatin: 'Ardeola ralloides',
    nameEn: '',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.m.wikipedia.org/wiki/R%C3%A4%C3%A4kk%C3%A4haikara'
  },
  {
    id: 'b-368',
    orderLatin: 'Ciconiiformes',
    orderFi: 'Haikaralinnut',
    familyLatin: 'ciconiidae',
    familyFi: 'Iiibikset',
    nameFi: 'Pronssi-iibis',
    nameLatin: 'Plegadis falcinellus',
    nameEn: 'Glossy Ibis',
    rarity: 5,
    photo: 'birderkuva.jpg',
    photographer: '',
    detectLink: 'https://fi.wikipedia.org/wiki/Pronssi-iibis'
  }
]
